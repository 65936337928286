import { makeAutoObservable, runInAction } from 'mobx';
import { clearPersistedStore, makePersistable } from 'mobx-persist-store';

class UserAccessStore {
  userAccessState = null;

  constructor(rootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this);

    makePersistable(this, {
      name: 'UserAccessStore',
      properties: ['userAccessState'],
    });
  }

  set(id, value) {
    runInAction(() => {
      this.userAccessState = {
        ...this.userAccessState,
        [id]: value,
      };
    });
  }

  isEmpty() {
    return this.userAccessState === null;
  }

  delete(id) {
    delete this.userAccessState[id];
  }

  get(id) {
    try {
      return this.userAccessState[id];
    } catch {
      return null;
    }
  }

  async clearStore() {
    await clearPersistedStore(this);
  }
}

export default UserAccessStore;
