import { Close as CloseIcon } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { nanoid } from 'nanoid';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import IconChip from '../../composite/IconChip';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  zIndex: 100,
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{ m: 0, pt: 2, pl: 2, pr: 2, pb: 1, color: 'primary.main' }}
      {...other}>
      {children}
      {onClose ? (
        <IconButton
          variant="contained"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
            background: '#ffffff',
          }}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

function Modal({
  title = '',
  children = '',
  actions = null,
  handleClose = () => undefined,
  open = false,
  PaperProps = {},
  Icon = null,
  ...props
}) {
  return (
    <BootstrapDialog
      className="modalPopUp"
      PaperProps={PaperProps}
      onClose={handleClose}
      open={open}
      {...props}>
      <BootstrapDialogTitle onClose={handleClose}>
        <Grid2 container justifyContent="left" alignItems="center" spacing={1}>
          {Icon && (
            <Grid2>
              <IconChip
                bgColor="primary.main"
                iconColor="#ffffff"
                Icon={Icon}
                size={30}
              />
            </Grid2>
          )}
          <Grid2>{title}</Grid2>
        </Grid2>
      </BootstrapDialogTitle>
      <DialogContent sx={{ mt: 2, mb: 2 }}>{children}</DialogContent>
      {actions && (
        <DialogActions sx={{ mb: 2, justifyContent: 'center' }}>
          {actions.map(itemProps => (
            <Button key={nanoid()} {...itemProps} />
          ))}
        </DialogActions>
      )}
    </BootstrapDialog>
  );
}

export default Modal;
