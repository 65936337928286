import React from 'react';

export const defaultDataProvider = () => ({
  default: {
    getApiUrl: () => '',
    getMany: Promise.resolve({ data: [] }),
    getOne: Promise.resolve({ data: null }),
    deleteOne: Promise.resolve({ data: null }),
    createOne: Promise.resolve({ data: null }),
    updateOne: Promise.resolve({ data: null }),
    custom: () => Promise.resolve({ data: null }),
  },
});

export const DataContext = React.createContext(defaultDataProvider());

export default function DataProvider({ children, dataProviders = null }) {
  let dataProviderCollection = null;

  if (dataProviders?.default) {
    dataProviderCollection = dataProviders;
  } else {
    throw new Error('Data provider needs atleast one "default" named provider');
  }

  return (
    <DataContext.Provider value={dataProviderCollection}>
      {children}
    </DataContext.Provider>
  );
}

export const useDataProvider = (context = 'default') => {
  const dataProviders = React.useContext(DataContext);

  return dataProviders[context];
};
