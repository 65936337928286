import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  Stack,
  TextField,
} from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { DatePicker as DatePickerMui } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { observer } from 'mobx-react';
import { Clear } from '@mui/icons-material';
import { Fragment, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { DYNAMIC_FORM_MODE } from '../DynamicForm/lib/constants';
import FormValue from '../FormValue';
import applyEffects from '../DynamicForm/lib/effects';
import { useStore } from '../../MobxProvider';

export const DATE_FORMAT = {
  SHORT_DATE: 'MMM D',
  MEDIUM_DATE: 'MMM D, YYYY',
  LONG_DATE: 'MMM D, YYYY h:mma',
  STANDARD_DATE: 'YYYY-MM-DD',
};

function DatePicker(props) {
  return <DatePickerMui {...props} />;
}

const mergeAdornments = (...adornments) => {
  const nonNullAdornments = adornments.filter(el => el != null);
  if (nonNullAdornments.length === 0) {
    return null;
  }

  if (nonNullAdornments.length === 1) {
    return nonNullAdornments[0];
  }

  return (
    <Stack direction="row">
      {nonNullAdornments.map((adornment, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Fragment key={index}>{adornment}</Fragment>
      ))}
    </Stack>
  );
};

function DatePickerTextField({ onClearClick = () => undefined, ...rest }) {
  return (
    <TextField
      {...rest}
      InputProps={{
        ...rest.InputProps,
        endAdornment: mergeAdornments(
          <InputAdornment position="end">
            <IconButton onClick={() => onClearClick()}>
              <Clear />
            </IconButton>
          </InputAdornment>,
          rest.InputProps?.endAdornment ?? null,
        ),
      }}
    />
  );
}

export const DatePickerFormControl = observer(props => {
  const {
    label,
    localeId = null,
    helperText,
    control,
    name,
    fullWidth = false,
    formMode,
    dateFormat = DATE_FORMAT.MEDIUM_DATE,
    effects = null,
    effectFunctions = null,
    maxDateField = null,
    minDateField = null,
    formId,
    dataType,
    ...rest
  } = props;

  const store = useStore();

  const [minDate, setMinDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);

  const effectProps = effectFunctions
    ? applyEffects(effects, effectFunctions)
    : null;

  useEffect(() => {
    if (store.domain.formState.getState(formId)) {
      if (minDateField) {
        const stateValue =
          store.domain.formState.getState(formId)[minDateField];
        if (stateValue) {
          setMinDate(stateValue);
        }
      }
      if (maxDateField) {
        const stateValue =
          store.domain.formState.getState(formId)[maxDateField];
        if (stateValue) {
          setMaxDate(stateValue);
        }
      }
    }
  }, [store.domain.formState.getState(formId)]);

  const { disabled = false } = effectProps || { disabled: false };

  return (
    <Controller
      control={control}
      name={name}
      {...props}
      render={({ field = {}, fieldState = {} }) => {
        const { onChange, value, ref, ...fieldRest } = field;
        return (
          <FormControl className="Mui-datepicker" fullWidth={fullWidth}>
            <InputLabel
              {...(fieldState?.error?.message && {
                error: !!fieldState?.error?.message,
              })}
              sx={{ mt: 1 }}>
              <FormattedMessage id={localeId} defaultMessage={label} />
            </InputLabel>
            {formMode === DYNAMIC_FORM_MODE.VIEW || disabled ? (
              <FormValue>
                {value ? dayjs(value)?.format(dateFormat) : value}
              </FormValue>
            ) : (
              <>
                <DatePickerMui
                  value={value === null || value === '' ? null : dayjs(value)}
                  onChange={dateValue => {
                    onChange(
                      dateValue
                        ? dateValue.format(DATE_FORMAT.STANDARD_DATE)
                        : '',
                    );
                  }}
                  {...fieldRest}
                  {...rest}
                  slotProps={{
                    textField: {
                      onClearClick: () => {
                        onChange(null);
                      },
                      size: 'small',
                    },
                  }}
                  slots={{
                    textField: DatePickerTextField,
                  }}
                  {...effectProps}
                  {...(minDateField && minDate
                    ? { minDate: dayjs(minDate) }
                    : {})}
                  {...(maxDateField && maxDate
                    ? { maxDate: dayjs(maxDate) }
                    : {})}
                />
                <FormHelperText
                  {...(fieldState?.error?.message && {
                    error: !!fieldState?.error?.message,
                  })}>
                  {fieldState?.error?.message
                    ? fieldState?.error?.message
                    : helperText}
                </FormHelperText>
              </>
            )}
          </FormControl>
        );
      }}
    />
  );
});

export default observer(DatePicker);
