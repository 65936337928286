import { Box, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import SVGError from './error.svg';

function ErrorDisplay({
  text = null,
  subText = null,
  buttonComponent = null,
  errorMessage = null,
}) {
  return (
    <Grid2
      container
      sx={{ flexDirection: { xs: 'column', md: 'row' } }}
      spacing={2}>
      <Grid2 xs={12} md={6} alignSelf="center">
        {text && <Typography variant="h5">{text}</Typography>}
        {subText && <Typography>{subText}</Typography>}
        {errorMessage && (
          <Box sx={{ mb: 4 }}>
            <Typography mt={4} variant="subtitle1" color="error">
              {errorMessage}
            </Typography>
          </Box>
        )}
        {buttonComponent && (
          <Typography mt={4} variant="subtitle1">
            {buttonComponent}
          </Typography>
        )}
      </Grid2>
      <Grid2 xs={12} md={6}>
        <Grid2>
          <img src={SVGError} alt="error" />
        </Grid2>
        <Grid2 textAlign="center">
          <Typography>
            <a href="https://storyset.com/people">
              People illustrations by Storyset
            </a>
          </Typography>
        </Grid2>
      </Grid2>
    </Grid2>
  );
}

export default ErrorDisplay;
