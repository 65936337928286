import { Badge, IconButton, styled, useTheme } from '@mui/material';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';

const AdjustedBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    right: 2,
    top: 5,
  },
}));

function ReadNotificationButton({ count, onClick }) {
  const theme = useTheme();
  return (
    <Badge badgeContent={count} color="success">
      <IconButton onClick={onClick} sx={{ color: theme.palette.tertiary.main }}>
        <NotificationsNoneIcon sx={{ color: theme.palette.tertiary }} />
      </IconButton>
    </Badge>
  );
}

function UnReadNotificationButton({ count, onClick }) {
  const theme = useTheme();
  return (
    <AdjustedBadge badgeContent={count} color="warning">
      <IconButton
        onClick={onClick}
        sx={{
          borderColor: theme.palette.warning.main,
          borderWidth: '2px',
          borderStyle: 'solid',
        }}>
        <NotificationsNoneIcon sx={{ color: theme.palette.warning.main }} />
      </IconButton>
    </AdjustedBadge>
  );
}

export const NotificationType = {
  READ: 'READ',
  UNREAD: 'UNREAD',
};

function NotificationButton({
  type = NotificationType.READ,
  count = 0,
  onClick = () => undefined,
}) {
  if (type === NotificationType.UNREAD && count > 0) {
    return <UnReadNotificationButton onClick={onClick} count={count} />;
  }
  return <ReadNotificationButton onClick={onClick} count={count} />;
}

export default NotificationButton;
