import axios from 'axios';
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
import { nanoid } from 'nanoid';

const apiClient = (baseUrl, token, lang) => {
  let sessionState = null;
  if (token) {
    try {
      const decodedToken = jwt_decode(token);
      sessionState = decodedToken?.session_state || null;
    } catch {
      sessionState = null;
    }
  }
  const axiosInstance = axios.create({
    baseURL: baseUrl,
    withCredentials: true,
    headers: {
      common: {
        'x-lang': lang.get(),
        'Accept-Language': lang.get(),
        Authorization: `Bearer ${token}`,
        ...(sessionState && { sessionid: sessionState }),
      },
    },
  });

  axiosInstance.interceptors.request.use(config => ({
    ...config,
    headers: {
      ...config.headers,
      requestid: nanoid(21),
    },
  }));

  axiosInstance.interceptors.response.use(
    response => response,
    error => {
      if (
        error?.code === 'ERR_NETWORK' ||
        error?.response?.status === 503 ||
        error?.response?.status === 422
      ) {
        const errorMessage = {
          message: encodeURIComponent(
            `Network error when accessing url ${error?.config?.url}`,
          ),
        };
        window.location.href = `/servicedown?networkError=${JSON.stringify(
          errorMessage,
        )}`;
      }
      if (error.response.status === 401 || error.response.status === 403) {
        window.location.href = '/unauthorized';
      }
      if (error.response.status === 500) {
        const errorMessage = {
          message: encodeURIComponent(
            `Server error when accessing url ${error?.config?.url}`,
          ),
        };
        window.location.href = `/servererror?serverError=${JSON.stringify(
          errorMessage,
        )}`;
      }
      // 400 bad request return response
      return error.response;
    },
  );

  return axiosInstance;
};

export default apiClient;
