import { Breadcrumbs as BreadcrumbsMui, Link, Typography } from '@mui/material';

function Breadcrumbs({ links }) {
  return (
    <BreadcrumbsMui>
      {links.map(({ label, href, ...linkItemProps }, index) => {
        if (index !== links.length - 1) {
          return (
            <Link
              key={label}
              underline="hover"
              color="primary.main"
              href={href}
              {...linkItemProps}>
              {label}
            </Link>
          );
        }
        return null;
      })}
      <Typography color="text.primary">
        {links[links.length - 1].label}
      </Typography>
    </BreadcrumbsMui>
  );
}

export default Breadcrumbs;
