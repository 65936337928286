const getEffect = (effectProp, effectFunctionName, effectFunctions) => ({
  [effectProp]: effectFunctions[effectFunctionName],
});

const applyEffects = (effects, effectFunctions) => {
  if (effects) {
    return Object.entries(effects).reduce(
      (acc, [effectProp, effectFunctionName]) => ({
        ...acc,
        ...getEffect(effectProp, effectFunctionName, effectFunctions),
      }),
      {},
    );
  }
  return null;
};

export default applyEffects;
