/* eslint-disable no-nested-ternary */
import { useState } from 'react';
import {
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  TextField as TextFieldMui,
  styled,
} from '@mui/material';
import { FormattedMessage } from 'react-intl';
import 'core-js/es/string/ends-with';
import { Controller } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';
import useMediaQuery from '@mui/material/useMediaQuery';
import { DYNAMIC_FORM_MODE } from '../DynamicForm/lib/constants';
import FormValue from '../FormValue';
import Required from '../Required';
import applyEffects from '../DynamicForm/lib/effects';
import FormatterUtil from '../../Utils/FormatterUtil';
import Utils from '../../Utils';

const TextFieldMuiStyled = styled(TextFieldMui)(() => ({
  '& .MuiOutlinedInput-notchedOutline ': {
    top: 0,
  },
  '& .MuiInputAdornment-root.MuiInputAdornment-positionStart': {
    marginRight: '2px',
  },
}));

function TextField(props) {
  return <TextFieldMuiStyled {...props} />;
}

export function TextFieldFormControl(props) {
  const {
    control,
    name,
    formMode = DYNAMIC_FORM_MODE.VIEW,
    required = false,
    helperText,
    label = '',
    localeId = null,
    fullWidth = false,
    type = 'text',
    InputProps = null,
    hasConstantValue,
    containerComponent,
    jsonSchema,
    masterData,
    decimal = 0,
    format = null,
    customformat = null,
    effects = null,
    effectFunctions = null,
    formId,
    dataType,
    ...textfieldProps
  } = props;

  const [focused, setFocused] = useState(false);

  let TextInputProps = InputProps;

  if (InputProps) {
    if (InputProps?.startAdornment) {
      const {
        children = '',
        childType = 'text',
        position = 'start',
      } = InputProps?.startAdornment || {};

      let child = '';

      if (childType === 'text') {
        child = children;
      }

      // TODO: Add logic if childType is icon

      TextInputProps = {
        InputProps: {
          startAdornment: (
            <InputAdornment position={position}>{child}</InputAdornment>
          ),
        },
      };
    }
  }

  const getValue = (value, valueFormat) => {
    if (valueFormat === 'email') {
      if (value === '') {
        return null;
      }
    }

    return value === null ? '' : value;
  };

  const effectProps = effectFunctions
    ? applyEffects(effects, effectFunctions)
    : null;

  const { disabled = false } = effectProps || { disabled: false };

  const Mobile = useMediaQuery('(max-width:500px)');

  return (
    <Controller
      control={control}
      name={name}
      {...props}
      render={data => {
        let displayValue = data?.field?.value || '';
        const setLabel = data?.formState?.defaultValues;
        let dynamicLabel = '';
        let dynamicLabelLocalId = '';
        if (localeId === 'SERVICE_CONTRACT_NUMBER_SA') {
          dynamicLabel = `Service Contract Number (${
            setLabel?.serviceAdministratorName
              ? Utils.capitalizeFirstLetter(setLabel?.serviceAdministratorName)
              : 'Service Administrator'
          })`;
          dynamicLabelLocalId = 'SERVICE_CONTRACT_NUMBER_SA_ID';
        } else if (localeId === 'SERVICE_CONTRACT_NUMBER_SP') {
          dynamicLabel = `Service Contract Number (${
            setLabel?.serviceProvider?.serviceProviderName
              ? Utils.capitalizeFirstLetter(
                  setLabel?.serviceProvider?.serviceProviderName,
                )
              : 'Service Provider'
          })`;
          dynamicLabelLocalId = 'SERVICE_CONTRACT_NUMBER_SP_ID';
        } else if (localeId === 'SERVICE_PROVIDER_JOB_NUMBER') {
          if (setLabel?.serviceProvider?.serviceProviderName) {
            dynamicLabel = `Job Number (${Utils.capitalizeFirstLetter(
              setLabel?.serviceProvider?.serviceProviderName,
            )})`;
            dynamicLabelLocalId = 'SERVICE_PROVIDER_JOB_NUMBER_ID';
          } else {
            dynamicLabel = label;
            dynamicLabelLocalId = localeId;
          }
        } else if (localeId === 'SERVICE_PROVIDER_INVOICE_NUMBER') {
          dynamicLabel = `Invoice Number (${
            setLabel?.serviceProvider?.serviceProviderName
              ? Utils.capitalizeFirstLetter(
                  setLabel?.serviceProvider?.serviceProviderName,
                )
              : 'Service Provider'
          })`;
          dynamicLabelLocalId = 'SERVICE_PROVIDER_INVOICE_NUMBER_ID';
        } else {
          dynamicLabel = label;
          dynamicLabelLocalId = localeId;
        }
        if (type === 'number') {
          displayValue = data?.field?.value || '';
          if (displayValue) {
            if (decimal) {
              displayValue = parseFloat(displayValue).toFixed(decimal);
            }
          }
        } else if (customformat) {
          if (customformat === 'phone') {
            displayValue = FormatterUtil.formatPhoneNumber(displayValue, 'US');
          } else if (customformat === 'zipcode') {
            displayValue = FormatterUtil.formatZipCode(displayValue, 'US');
          }
        }

        if (TextInputProps?.InputProps?.startAdornment && displayValue) {
          displayValue = `${InputProps?.startAdornment?.children}${displayValue}`;
        }

        return (
          <FormControl
            fullWidth={fullWidth}
            sx={{
              marginTop: Mobile ? '0.5rem' : '-0.2rem',
            }}>
            {label && (
              <InputLabel
                focused={!!focused}
                {...(data?.fieldState?.error?.message && {
                  error: !!data?.fieldState?.error?.message,
                })}
                sx={{
                  marginTop: '0.4rem',
                  whiteSpace: 'break-spaces',
                  wordBreak: 'break-word',
                }}>
                <FormattedMessage
                  id={dynamicLabelLocalId}
                  defaultMessage={dynamicLabel}
                />
                <Required show={required} />
              </InputLabel>
            )}
            {formMode === DYNAMIC_FORM_MODE.VIEW || disabled ? (
              <FormValue>{displayValue}</FormValue>
            ) : type === 'number' ? (
              <NumericFormat
                customInput={TextFieldMui}
                autoComplete="off"
                onFocus={() => setFocused(true)}
                InputLabelProps={{ shrink: true }}
                error={!!data?.fieldState?.error?.message}
                {...TextInputProps}
                {...textfieldProps}
                fullWidth
                type={type === 'number' ? 'text' : type}
                onBlur={() => {
                  setFocused(false);
                  data?.field?.onBlur();
                }}
                onChange={e =>
                  e.target.value
                    ? data?.field?.onChange(Number(e.target.value))
                    : data?.field?.onChange(0)
                }
                value={data?.field?.value === null ? '' : data?.field?.value}
                allowNegative={false}
                decimalScale={decimal}
                fixedDecimalScale
                {...effectProps}
              />
            ) : (
              <TextFieldMuiStyled
                autoComplete="off"
                onFocus={() => setFocused(true)}
                InputLabelProps={{ shrink: true }}
                error={!!data?.fieldState?.error?.message}
                {...data.field}
                {...TextInputProps}
                {...textfieldProps}
                fullWidth
                value={getValue(data?.field?.value, format)}
                type={type === 'number' ? 'text' : type}
                onBlur={() => {
                  setFocused(false);
                  data?.field?.onBlur();
                }}
                onChange={e =>
                  data?.field?.onChange(getValue(e.target.value, format))
                }
                {...effectProps}
              />
            )}
            <FormHelperText
              {...(data?.fieldState?.error?.message && {
                error: !!data?.fieldState?.error?.message,
              })}>
              {data?.fieldState?.error?.message
                ? data?.fieldState?.error?.message
                : helperText}
            </FormHelperText>
          </FormControl>
        );
      }}
    />
  );
}

export default TextField;
