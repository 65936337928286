/* eslint-disable import/no-cycle */
import { useFieldArray } from 'react-hook-form';
import { nanoid } from 'nanoid';
import { DeleteForever, Handyman } from '@mui/icons-material';
import { Box, Button, Divider, IconButton, styled } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { FormattedMessage } from 'react-intl';
// import useMediaQuery from '@mui/material/useMediaQuery';
import renderComponents from '../DynamicForm/lib/renderComponents';
import { DYNAMIC_FORM_MODE } from '../DynamicForm/lib/constants';
import FormJsonSchemaUtil from '../DynamicForm/lib/jsonSchemaUtil';
import applyEffects from '../DynamicForm/lib/effects';

const ButtonStyled = styled(Button)({
  textTransform: 'none',
});

// This component is responsive for both devices
// PC,Tab and also the Mobile screen
// The  @media  is using for the mobile screen

function IterativeFieldControl(props) {
  const {
    setValue,
    control,
    formId,
    formMode = DYNAMIC_FORM_MODE.VIEW,
    fields,
    jsonSchema = {},
    masterData,
    additionalComponents,
    effectFunctions = null,
    addButtonEffect = null,
    formatMessage,
    arrayGroup,
    getValues,
  } = props;

  const fieldsToRender = fields.reduce((acc, field) => {
    const jsonSchemaName = field['jsonschema:name'];
    if (jsonSchemaName) {
      const simpleName = jsonSchemaName?.replace(/properties./g, '');
      const names = simpleName?.split('.items.');
      const key = names[1];
      return [...acc, key];
    }
    return acc;
  }, []);

  const fieldobject = fieldsToRender.reduce((acc, fieldToRender) => {
    const newField =
      FormJsonSchemaUtil.createNestedObjectFromDotNotation(fieldToRender);
    return {
      ...acc,
      ...newField,
    };
  }, {});

  const arrayGroupHtmlName = FormJsonSchemaUtil.getHtmlNameFromSchemaName({
    jsonSchemaName: arrayGroup,
  });

  const {
    fields: reactHookFields,
    append,
    remove,
  } = useFieldArray({
    name: arrayGroupHtmlName,
    control,
  });

  const addRow = () => {
    append(fieldobject);
  };

  const removeRow = id => {
    remove(id);
  };

  const addButtonEffectResult = effectFunctions
    ? applyEffects(addButtonEffect, effectFunctions)
    : null;

  const editableMode =
    formMode === DYNAMIC_FORM_MODE.CREATE ||
    formMode === DYNAMIC_FORM_MODE.EDIT;

  let canEdit = false;
  if (Object.prototype.hasOwnProperty.call(addButtonEffectResult, 'editable')) {
    canEdit = addButtonEffectResult?.editable === true;
  } else {
    canEdit = true;
  }

  // responsive on mobile
  // const isMobile = useMediaQuery('(max=width:500px');
  // console.log('isMobile', isMobile);
  return (
    <Grid2>
      {editableMode && canEdit && (
        <Grid2 marginTop={-3} mb={2} textAlign="right">
          <ButtonStyled
            size="small"
            type="button"
            variant="contained"
            startIcon={<Handyman />}
            onClick={() => {
              addRow();
            }}>
            <FormattedMessage id="ADD_NEW_PART" defaultMessage="Add New Part" />
          </ButtonStyled>
        </Grid2>
      )}
      <Box>
        {reactHookFields.map((_, rowIndex) => (
          <Grid2
            container
            spacing={2}
            key={nanoid()}
            sx={{
              '@media  (max-width:500px)': {
                display: 'contents',
              },
              '@media  (min-width:500px) and (max-width:768px)': {
                display: 'flex',
              },
            }}>
            {canEdit && (
              <Grid2
                textAlign="center"
                alignSelf="center"
                style={{ width: '100px' }}>
                <IconButton
                  onClick={() => {
                    removeRow(rowIndex);
                  }}
                  color="error">
                  <DeleteForever />
                </IconButton>
              </Grid2>
            )}
            <Grid2 xs mb={2}>
              <Box>
                <Grid2
                  container
                  direction="row"
                  spacing={2}
                  sx={{
                    '@media  (max-width:500px)': {
                      display: 'contents',
                    },
                    '@media  (min-width:500px) and (max-width:768px)': {
                      display: 'flex',
                    },
                  }}>
                  {fields.map(field => (
                    <Grid2
                      xs
                      key={nanoid()}
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        '@media  (max-width:500px)': {
                          display: 'contents',
                        },
                        '@media (min-width:500px) and (max-width:768px)': {
                          display: 'table',
                          width: '5rem',
                        },
                      }}>
                      {renderComponents({
                        uiSchema: field,
                        additionalComponents,
                        control,
                        jsonSchema,
                        formMode,
                        masterData,
                        formId,
                        effectFunctions,
                        formatMessage,
                        itemsArrayIndex: rowIndex,
                        setValue,
                        getValues,
                      })}
                    </Grid2>
                  ))}
                </Grid2>
              </Box>
            </Grid2>
            {reactHookFields.length > 1 && (
              <Grid2 xs="12">
                <Divider />
              </Grid2>
            )}
          </Grid2>
        ))}
      </Box>
    </Grid2>
  );
}

export default IterativeFieldControl;
