import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { useEffect, useImperativeHandle, useState } from 'react';
import { DialogTitle } from '@mui/material';
import LoadingEvent from '../LoadingEvent';

function ConfirmationPopUp({ reference }) {
  const defaultOptions = {
    message: null,
    positiveButtonText: 'Yes',
    negativeButtonText: 'No',
    loadingMessage: 'Processing...',
    width: 360,
    title: null,
    onChange: () => undefined,
  };

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState(defaultOptions);

  useImperativeHandle(reference, () => ({
    setOpen(value) {
      setOpen(value);
    },
    setLoading(value) {
      setLoading(value);
    },
    init(popupOptions) {
      setOptions(popupOptions);
    },
  }));

  useEffect(() => {
    if (!open) {
      setLoading(false);
      setOptions(defaultOptions);
    }
  }, [open]);

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: options?.width,
        bgcolor: 'background.paper',
      }}>
      <Dialog
        sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
        maxWidth="xs"
        open={open}>
        {options?.title && !loading && (
          <DialogTitle style={{ padding: '8px 20px 30px', fontSize: '18px' }}>
            {options?.title}
          </DialogTitle>
        )}
        {loading ? (
          <DialogContent>
            <LoadingEvent>{options?.loadingMessage}</LoadingEvent>
          </DialogContent>
        ) : (
          <DialogContent>{options?.message}</DialogContent>
        )}
        {!loading ? (
          <DialogActions
            style={{
              justifyContent: 'center',
              marginTop: '10px',
              marginBottom: '10px',
            }}>
            <Button variant="contained" onClick={() => options?.onChange(true)}>
              {options?.positiveButtonText}
            </Button>
            <Button
              variant="outlined"
              style={{ marginLeft: '20px' }}
              onClick={() => options?.onChange(false)}>
              {options?.negativeButtonText}
            </Button>
          </DialogActions>
        ) : null}
      </Dialog>
    </Box>
  );
}

export default ConfirmationPopUp;
