import { LinearProgress, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%,-50%)',
  width: '100%',
};

function PageLoading({ children = null }) {
  return (
    <Grid2 style={style} container>
      {children && (
        <Grid2 textAlign="center" sx={{ mb: 2 }} xs={10} xsOffset={1}>
          <Typography variant="h6" component="div">
            {children}
          </Typography>
        </Grid2>
      )}
      <Grid2 alignContent="center" xs={10} xsOffset={1}>
        <LinearProgress />
      </Grid2>
    </Grid2>
  );
}

export default PageLoading;
