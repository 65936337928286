import { makeAutoObservable, runInAction } from 'mobx';
import { clearPersistedStore, makePersistable } from 'mobx-persist-store';

class FormStateStore {
  formState = {};

  constructor(rootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this);

    makePersistable(this, {
      name: 'FormStateStore',
      properties: ['formState'],
    });
  }

  setState(id, value) {
    runInAction(() => {
      this.formState[id] = value;
    });
  }

  deleteState(id) {
    delete this.formState[id];
  }

  getState(id) {
    return this.formState[id];
  }

  async clearStore() {
    await clearPersistedStore(this);
  }
}

export default FormStateStore;
