import { styled } from '@mui/material/styles';
import { Box, useTheme } from '@mui/material';
import Utils from '../../core/Utils';

export const Circle = styled(Box, {
  shouldForwardProp: props => !['bgColor', 'size'].includes(props),
})(({ theme, bgColor, size }) => ({
  backgroundColor: bgColor,
  padding: theme.spacing(1),
  width: `${size}px`,
  height: `${size}px`,
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

function IconChip({ bgColor, iconColor, Icon, size }) {
  const theme = useTheme();

  const bgColorValue = Utils.getColorFromString(theme.palette, bgColor);
  const iconColorValue = Utils.getColorFromString(theme.palette, iconColor);

  return (
    <Circle size={size} bgColor={bgColorValue || bgColor}>
      <Icon
        sx={{ color: iconColorValue || iconColor, fontSize: `${size / 2.2}px` }}
      />
    </Circle>
  );
}

export default IconChip;
