import gPhoneNumber from 'google-libphonenumber';
import LoggerUtil from './LoggerUtil';

const FormatterUtil = {
  formatPhoneNumber(phoneNumberString, countryCode) {
    try {
      const phoneUtil = gPhoneNumber.PhoneNumberUtil.getInstance();
      if (phoneNumberString.length > 2) {
        const phoneNumber = phoneUtil.parseAndKeepRawInput(
          phoneNumberString,
          countryCode,
        );

        if (!phoneUtil.isPossibleNumber(phoneNumber)) {
          LoggerUtil.log('Not a possible phonenumber', {
            phoneNumberString,
          });
          return phoneNumberString;
        }

        const formattedNumber = phoneUtil.format(phoneNumber, countryCode);

        return formattedNumber === '' ? phoneNumberString : formattedNumber;
      }
    } catch (e) {
      LoggerUtil.log('Phone Format Issue', {
        phoneNumberString,
        error: e.message,
      });
    }

    return phoneNumberString;
  },
  formatZipCode(zipcode, countryCode) {
    if (countryCode === 'US') {
      if (zipcode) {
        let formattedZipCode = '';
        formattedZipCode = zipcode.replace(' ', '').replace('-', '');
        if (formattedZipCode.length === 9) {
          return `${formattedZipCode.slice(0, 5)}-${formattedZipCode.slice(5)}`;
        }
        if (formattedZipCode.length === 5) {
          return formattedZipCode;
        }
        return zipcode;
      }
    }
    return zipcode;
  },
};

export default FormatterUtil;
