import { Container } from '@mui/material';

function Page({ children, ...props }) {
  return (
    <Container maxWidth={false} {...props}>
      {children}
    </Container>
  );
}

export default Page;
