import { makeAutoObservable } from 'mobx';
import { clearPersistedStore, makePersistable } from 'mobx-persist-store';

class MasterDataStore {
  data = {};

  session = null;

  constructor(rootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this);

    makePersistable(this, {
      name: 'MasterDataStore',
      properties: ['data', 'session'],
    });
  }

  set(value) {
    this.data = value;
  }

  get() {
    return this.data;
  }

  setSession(value) {
    this.session = value;
  }

  getSession() {
    return this.session || null;
  }

  async clearStore() {
    await clearPersistedStore(this);
  }
}

export default MasterDataStore;
