import { Box, Typography, styled } from '@mui/material';
import IconChip from '../IconChip';

export const Container = styled(Box)(() => ({
  display: 'inline-flex',
  alignItems: 'center',
}));

function SectionTitle({ Icon = null, children, subSection = false }) {
  return (
    <Container>
      {Icon && !subSection && (
        <IconChip
          bgColor="primary.light"
          iconColor="primary.main"
          Icon={Icon}
          size={40}
        />
      )}
      {Icon && subSection && (
        <Icon sx={{ backgrooundColor: 'transparent', color: 'primary.main' }} />
      )}

      <Typography
        variant="h6"
        component="div"
        sx={{
          fontWeight: 500,
          marginLeft: '10px',
          ...(subSection && { fontSize: '18px' }),
        }}>
        {children}
      </Typography>
    </Container>
  );
}

export default SectionTitle;
