import {
  Box,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { useState } from 'react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import Logo, { LOGO_TYPE } from '../Logo';

const MENU_VIEW = {
  FULL: 'FULL',
  COMPACT: 'COMPACT',
};

function MenuItems({
  logoHref = null,
  logoTarget = '',
  menuItems,
  collapsed = false,
  setCollapse = () => undefined,
}) {
  const [menuView, setMenuView] = useState(
    collapsed ? MENU_VIEW.COMPACT : MENU_VIEW.FULL,
  );
  const [open, setOpen] = useState(
    menuItems.reduce((acc, item) => {
      const newAcc = {
        ...acc,
        ...{ [item.name]: false },
      };
      return newAcc;
    }, {}),
  );

  const getItem = (item, level) => {
    let expand = null;

    const expandSize = collapsed ? { fontSize: '14px' } : {};

    if (item?.children && level === 0) {
      expand = open[item.name] ? (
        <ExpandLess sx={expandSize} />
      ) : (
        <ExpandMore sx={expandSize} />
      );
    }

    const listItem = (
      <ListItemButton
        {...(collapsed && { sx: { mb: 1 } })}
        key={item.name}
        {...(level === 1 && { sx: { pl: 4 } })}
        onClick={() => {
          if (item.children) {
            const newOpenData = {
              ...open,
              ...{ [item.name]: !open[item.name] },
            };
            setOpen(newOpenData);
            if (level === 0) {
              setMenuView(collapsed ? MENU_VIEW.COMPACT : MENU_VIEW.FULL);
              setCollapse(false);
            }
            if (level === 1) {
              setCollapse(menuView === MENU_VIEW.COMPACT);
            }
          } else {
            item.onClick();
          }
        }}>
        <ListItemIcon sx={{ minWidth: '30px' }}>
          <item.icon />
        </ListItemIcon>
        {!collapsed && <ListItemText primary={item.label} />}
        {expand}
      </ListItemButton>
    );
    return listItem;
  };

  const items = menuItems
    .filter(item => item.hasPermission)
    .reduce((acc, item) => {
      let childrenItems = null;

      acc.push(getItem(item, 0));

      if (item?.children) {
        childrenItems = item.children
          .filter(subItem => subItem.hasPermission)
          .reduce((subAcc, subItem) => {
            subAcc.push(getItem(subItem, 1));
            return subAcc;
          }, []);

        if (childrenItems) {
          acc.push(
            <Collapse
              key={`collapse${item.name}`}
              timeout="auto"
              in={open[item.name]}>
              <List component="div" disablePadding>
                {childrenItems}
              </List>
            </Collapse>,
          );
        }
      }
      return acc;
    }, []);

  return (
    <List
      sx={{ width: '100%' }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <Box sx={{ mb: 5, pl: 1 }}>
          <Logo
            href={logoHref}
            target={logoTarget}
            size="medium"
            type={collapsed ? LOGO_TYPE.COMPACT : LOGO_TYPE.FULL}
          />
        </Box>
      }>
      {items}
    </List>
  );
}

export default MenuItems;
