import { Box, ToggleButton, useTheme } from '@mui/material';
import HistoryIcon from '@mui/icons-material/History';
import { useState } from 'react';
import SectionTitle from '../SectionTitle';
import Sidebar, { SidebarAnchor } from '../../core/Sidebar';

function RecentHistoryButton({ sideBarTitle = '', children }) {
  const theme = useTheme();
  const [selected, setSelected] = useState(false);

  const buttonStyle = {
    color: selected ? theme.palette.secondary.dark : 'transparent',
    borderRadius: '50%',
    border: 'none',
  };

  const iconStyle = {
    color: selected ? theme.palette.primary.main : theme.palette.tertiary.main,
  };

  return (
    <div>
      <ToggleButton
        value={1}
        selected={selected}
        sx={buttonStyle}
        onChange={() => {
          setSelected(!selected);
        }}>
        <HistoryIcon sx={iconStyle} />
      </ToggleButton>
      <Box>
        <Sidebar
          anchor={SidebarAnchor.RIGHT}
          open={selected}
          setOpen={setSelected}>
          <Box sx={{ marginBottom: '20px' }}>
            <SectionTitle>{sideBarTitle}</SectionTitle>
          </Box>
          {children}
        </Sidebar>
      </Box>
    </div>
  );
}

export default RecentHistoryButton;
