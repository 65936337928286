import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useStore } from '../../MobxProvider';
import FormValue from '../FormValue';
import applyEffects from '../DynamicForm/lib/effects';
import FormJsonSchemaUtil from '../DynamicForm/lib/jsonSchemaUtil';

function SumOperation(schemaItems, formValues) {
  return schemaItems.reduce((acc, item) => {
    const formElementName = FormJsonSchemaUtil.getHtmlNameFromSchemaName({
      jsonSchemaName: item['jsonschema:name'],
    });
    const value = parseFloat(
      FormJsonSchemaUtil.getValueFromObject(formValues, formElementName) || 0,
    );

    return parseFloat(acc + value);
  }, 0);
}

const operations = {
  SUM: SumOperation,
};

function CompositeFormControl(props) {
  const {
    operation,
    formId,
    items,
    prefix,
    type = 'text',
    fixedDecimal = null,
    effects = null,
    effectFunctions = null,
  } = props;
  const [result, setResult] = useState(null);
  const store = useStore();

  useEffect(() => {
    const currentValues = store.domain.formState.getState(formId);
    let calculatedValue = operations[operation](items, currentValues);
    if (type === 'number' && fixedDecimal) {
      calculatedValue = Number(calculatedValue);
      calculatedValue = parseFloat(calculatedValue).toFixed(
        parseInt(fixedDecimal, 10),
      );
    }
    setResult(`${prefix}${calculatedValue}`);
  }, [store.domain.formState.getState(formId)]);

  const effectProps = effectFunctions
    ? applyEffects(effects, effectFunctions)
    : null;

  return <FormValue {...effectProps}>{result}</FormValue>;
}

export default observer(CompositeFormControl);
