import { Typography } from '@mui/material';

function FormValue({ children = null, label = '', dataType, formId, ...rest }) {
  return (
    <Typography
      variant="body"
      sx={{ fontWeight: 500 }}
      color="tertiary.main"
      {...rest}>
      {children || label}
    </Typography>
  );
}

export default FormValue;
