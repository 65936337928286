import { Box, Drawer, IconButton, styled, useTheme } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

export const SidebarAnchor = {
  LEFT: 'left',
  RIGHT: 'right',
};

const StyledIconButton = styled(IconButton, {
  shouldForwardProp: props => !['anchor'].includes(props),
})(({ theme, anchor }) => ({
  boxShadow: theme.shadows[1],
  borderRadius: '8px',
  width: '32px',
  height: '32px',
  ...(anchor === SidebarAnchor.RIGHT && { left: '-20px' }),
  ...(anchor === SidebarAnchor.RIGHT && { top: '30px' }),
  ...(anchor === SidebarAnchor.LEFT && { right: '-98%' }),
  ...(anchor === SidebarAnchor.LEFT && { top: '30px' }),
  background: '#ffffff',
  ':hover': {
    background: theme.palette.secondary.light,
  },
}));

function CloseButton({ visible, anchor, onClick = () => undefined }) {
  const theme = useTheme();
  if (!visible) {
    return null;
  }
  return (
    <StyledIconButton onClick={onClick} anchor={anchor} variant="outlined">
      {anchor === SidebarAnchor.RIGHT && (
        <NavigateNextIcon sx={{ color: theme.palette.tertiary.main }} />
      )}
      {anchor === SidebarAnchor.LEFT && (
        <NavigateBeforeIcon sx={{ color: theme.palette.tertiary.main }} />
      )}
    </StyledIconButton>
  );
}

function Sidebar({
  children,
  anchor,
  open = false,
  setOpen = () => undefined,
}) {
  const theme = useTheme();

  return (
    <Drawer
      PaperProps={{
        sx: {
          overflow: 'visible',
          ...(anchor === SidebarAnchor.RIGHT && {
            borderTopLeftRadius: '20px',
          }),
          ...(anchor === SidebarAnchor.LEFT && {
            borderTopRightRadius: '20px',
          }),
          border: `2px solid ${theme.palette.secondary.light}`,
          maxWidth: '45%',
          zIndex: '100 !important',
        },
      }}
      variant="temporary"
      onClose={() => {
        setOpen(false);
      }}
      anchor={anchor}
      open={open}>
      <CloseButton
        onClick={() => {
          setOpen(false);
        }}
        visible={open}
        anchor={anchor}
      />
      <Box sx={{ padding: '0 15px 15px 15px' }}>{children}</Box>
    </Drawer>
  );
}

export default Sidebar;
