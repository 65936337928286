const LoggerUtil = {
  log(message, data = null) {
    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.log(message, data);
    }
  },
  forceLog(message, data = null) {
    // This function will be mostly used in storybook
    // eslint-disable-next-line no-console
    console.log(message, data);
  },
};

export default LoggerUtil;
