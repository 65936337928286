const dataProvider = axiosWrapper => ({
  getApiUrl: () => axiosWrapper.defaults.baseURL,
  getMany: async resource => {
    try {
      const response = await axiosWrapper.get(resource);
      return response?.data;
    } catch (error) {
      if (error?.response?.data) {
        return error?.response?.data;
      }
      return { error: [{ message: error.message }] };
    }
  },
  getOne: async (resource, data = null, config = null) => {
    try {
      const response = await axiosWrapper.get(resource, {
        ...(data && { params: data }),
        ...config,
      });
      return response.data;
    } catch (error) {
      if (error?.response?.data) {
        return error?.response?.data;
      }
      return { error: [{ message: error.message }] };
    }
  },
  deleteOne: async (resource, data) => {
    try {
      const response = await axiosWrapper.delete(resource, data);
      return response.data;
    } catch (error) {
      if (error?.response?.data) {
        return error?.response?.data;
      }
      return { error: [{ message: error.message }] };
    }
  },
  createOne: async (resource, data) => {
    try {
      const response = await axiosWrapper.put(resource, data);
      return response.data;
    } catch (error) {
      if (error?.response?.data) {
        return error?.response?.data;
      }
      return { error: [{ message: error.message }] };
    }
  },
  updateOne: async (resource, data) => {
    try {
      const response = await axiosWrapper.post(resource, data);
      return response.data;
    } catch (error) {
      if (error?.response?.data) {
        return error?.response?.data;
      }
      return { error: [{ message: error.message }] };
    }
  },
  custom: () => Promise.resolve({ data: null }),
});

export default dataProvider;
