/* eslint-disable camelcase */
import { makeAutoObservable, runInAction } from 'mobx';
import { clearPersistedStore, makePersistable } from 'mobx-persist-store';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import { MRT_Localization_EN } from 'material-react-table/locales/en';

const defaultLanguage = 'en';
class LocaleStore {
  language = defaultLanguage;

  languagesSupported = null;

  constructor(rootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this);

    makePersistable(this, {
      name: 'LocaleStore',
      properties: ['language', 'languagesSupported'],
    });
  }

  set(value = defaultLanguage) {
    runInAction(() => {
      if (value) {
        this.language = value;
      }
    });
  }

  setSupportedLanguages(value = []) {
    runInAction(() => {
      if (Array.isArray(value)) {
        this.languagesSupported = value;
      }
    });
  }

  get() {
    return this.language || defaultLanguage;
  }

  getSupportedLanguages() {
    return this.languagesSupported;
  }

  getForMuiTable() {
    return this.language === 'en' ? MRT_Localization_EN : MRT_Localization_ES;
  }

  async clearStore() {
    await clearPersistedStore(this);
  }
}

export default LocaleStore;
