import { CircularProgress, useTheme } from '@mui/material';
import ReactSelect from 'react-select';
import AsyncSelect from 'react-select/async';

const SelectTheme = (theme, selectThemeObj) => ({
  ...selectThemeObj,
  borderRadius: theme.shape.borderRadius,
  colors: {
    ...selectThemeObj.colors,
    primary: theme.palette.primary.main,
  },
  spacing: {
    ...selectThemeObj.spacing,
    controlHeight: 40,
  },
});

function SelectComponent({
  initialized,
  isClearable,
  setFocused,
  dataSet,
  optionsAttribute,
  isMulti,
  value,
  onChange,
  async,
  loadOptions,
  optionMapper,
  optionLabelKey,
  optionValueKey,
  hookFormProps,
  optionTypeFlatArray = false,
  name,
  ...rest
}) {
  const theme = useTheme();
  const ReactSelectComponent = async ? AsyncSelect : ReactSelect;

  if (!initialized) {
    return <CircularProgress size={15} />;
  }

  return (
    <ReactSelectComponent
      isClearable={isClearable}
      onMenuOpen={() => setFocused(true)}
      onMenuClose={() => setFocused(false)}
      styles={{
        menuPortal: base => ({ ...base, zIndex: 9999 }),
      }}
      theme={themeObj => SelectTheme(theme, themeObj)}
      classNames={{ control: () => 'react-select-control' }}
      menuPortalTarget={document.body}
      isMulti={isMulti}
      value={
        value ? dataSet.find(c => c[optionsAttribute.value] === value) : ''
      }
      onChange={selectedObj => {
        if (isMulti) {
          const selectedValueArray =
            selectedObj?.reduce(
              (acc, item) => [...acc, item[optionsAttribute.value]],
              [],
            ) || [];
          onChange(selectedValueArray);
        } else {
          onChange(
            selectedObj ? selectedObj[optionsAttribute.value] : selectedObj,
          );
        }
      }}
      {...(async
        ? {
            loadOptions: async inputValue => {
              let data = await loadOptions(inputValue);

              if (optionTypeFlatArray) {
                data = data.reduce(
                  (acc, item) => [...acc, { label: item, value: item }],
                  [],
                );
              } else {
                data = optionMapper(data, optionLabelKey, optionValueKey);
              }

              return data;
            },
            defaultOptions: true,
            cacheOptions: true,
          }
        : {
            options: dataSet,
            getOptionLabel: option => option[optionsAttribute.label],
            getOptionValue: option => option[optionsAttribute.value],
            value: value
              ? dataSet.find(c => c[optionsAttribute.value] === value)
              : '',
          })}
      {...hookFormProps}
      {...rest}
    />
  );
}

export default SelectComponent;
