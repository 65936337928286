function getDefaultValue(key, props) {
  let defaultValue = null;

  if (props?.const) {
    defaultValue = props.const;
  } else if (props?.default) {
    defaultValue = props.default;
  } else {
    defaultValue = null;
  }

  return defaultValue;
}

function getDefaultValues(jsonSchema) {
  if (jsonSchema) {
    return Object.entries(jsonSchema?.properties).reduce(
      (acc, [key, value]) => {
        if (value?.properties) {
          return {
            ...acc,
            ...{ [key]: getDefaultValues(value) },
          };
        }
        return {
          ...acc,
          ...{
            [key]: getDefaultValue(key, value),
          },
        };
      },
      {},
    );
  }
  return {};
}

export function handleNullValue(obj, nullValue = null) {
  const newObj = obj;
  // eslint-disable-next-line consistent-return
  Object.keys(obj).forEach(key => {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      return handleNullValue(obj[key], nullValue);
    }
    if (obj[key] === null) {
      newObj[key] = nullValue;
    } else {
      newObj[key] = obj[key];
    }
  });
  return newObj;
}

export function flattenErrorObject(obj, parentKey = '') {
  // eslint-disable-next-line no-restricted-syntax
  if (obj && !Array.isArray(obj)) {
    return Object.entries(obj).reduce((acc, [key, subObj]) => {
      const combinedKey = parentKey ? `${parentKey}.${key}` : key;

      if (
        typeof obj[key] === 'object' &&
        Object.prototype.hasOwnProperty.call(obj[key], 'message')
      ) {
        return {
          ...acc,
          [combinedKey]: { type: 'custom', message: obj[key].message },
        };
      }
      return {
        ...acc,
        ...flattenErrorObject(subObj, combinedKey),
      };
    }, {});
  }
  return null;
}

export default getDefaultValues;
