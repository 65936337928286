import { useEffect, useState } from 'react';
import ReactSelect from 'react-select';
import { DYNAMIC_FORM_MODE } from '../DynamicForm/lib/constants';
import SelectController from './SelectController';
import applyEffects from '../DynamicForm/lib/effects';
import FormJsonSchemaUtil from '../DynamicForm/lib/jsonSchemaUtil';
/*
"menuPortalTarget" code is required so that dropdown is not hidden inside modal view
  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
  menuPortalTarget={document.body}
*/

const optionMapper = (options, labelKey, valueKey) =>
  options && Array.isArray(options)
    ? options.map(option => ({
        value: Object.keys(option).some(key => key === valueKey)
          ? option[valueKey]
          : null,
        label: Object.keys(option).some(key => key === labelKey)
          ? option[labelKey]
          : null,
      }))
    : [];

function Select(props) {
  const { options = [], optionTypeFlatArray = false, ...rest } = props;
  let dataSet = options;

  if (optionTypeFlatArray) {
    dataSet = dataSet.reduce(
      (acc, item) => [...acc, { label: item, value: item }],
      [],
    );
  }
  return (
    <ReactSelect
      styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
      menuPortalTarget={document.body}
      options={dataSet}
      {...rest}
    />
  );
}

export function SelectFormControl(props) {
  const {
    setValue,
    label,
    localeId = null,
    name,
    helperText,
    staticOptions = [],
    masterDataKey = null,
    masterData = [],
    control,
    fullWidth = false,
    isMulti = false,
    hasConstantValue = false,
    formMode = DYNAMIC_FORM_MODE.VIEW,
    optionTypeFlatArray = false,
    optionLabelKey = 'label',
    optionValueKey = 'value',
    isClearable = true,
    containerComponent,
    jsonSchema,
    formId,
    effects = null,
    effectFunctions = null,
    selectFirst = false,
    formatMessage = undefined,
    dataType,
    ...rest
  } = props;

  const optionsAttribute = {
    value: optionValueKey,
    label: optionLabelKey,
  };

  let dataSet = Array.isArray(staticOptions) ? staticOptions : [];

  if (optionTypeFlatArray) {
    dataSet = dataSet.reduce(
      (acc, item) => [...acc, { label: item, value: item }],
      [],
    );
  }

  if (masterDataKey && masterData) {
    dataSet = masterData[masterDataKey] || [];
  }

  const effectProps = effectFunctions
    ? applyEffects(effects, effectFunctions)
    : null;

  return (
    <SelectController
      setValue={setValue}
      control={control}
      name={name}
      dataSet={dataSet}
      fullWidth={fullWidth}
      label={label}
      localeId={localeId}
      hasConstantValue={hasConstantValue}
      formMode={formMode}
      helperText={helperText}
      isClearable={isClearable}
      isMulti={isMulti}
      optionMapper={optionMapper}
      optionsAttribute={optionsAttribute}
      optionLabelKey={optionLabelKey}
      optionValueKey={optionValueKey}
      {...rest}
      initialized
      async={false}
      loadOptions={() => undefined}
      getOptions={() => undefined}
      effectProps={effectProps}
      selectFirst={selectFirst}
      placeholder={formatMessage({
        id: 'SELECT_DROPDOWN',
        defaultMessage: 'Select',
      })}
    />
  );
}

export function AsyncSelectFormControl(props) {
  const {
    label,
    localeId = null,
    helperText,
    control,
    name,
    fullWidth = false,
    isMulti = false,
    optionTypeFlatArray = false,
    hasConstantValue = false,
    formMode = DYNAMIC_FORM_MODE.VIEW,
    optionLabelKey = 'label',
    optionValueKey = 'value',
    isClearable = true,
    fetchOptions = () => undefined,
    fetchOptionsById = () => undefined,
    containerComponent,
    jsonSchema,
    masterData,
    effects = null,
    effectFunctions = null,
    formatMessage = undefined,
    ...rest
  } = props;

  const { _formValues } = control;

  const [dataSet, setDataSet] = useState([]);
  const [initialized, setInitialized] = useState(false);

  const optionsAttribute = {
    value: 'value',
    label: 'label',
  };

  useEffect(() => {
    async function getDataSet() {
      setInitialized(false);
      let asyncDataSet = [];
      const formValue = FormJsonSchemaUtil.getValueFromObject(
        _formValues,
        name,
      );
      if (formValue) {
        // Remap option value to {label, value} array
        // just in case the object is not in {label, value} format
        // e.g brands array comes in as {brandCode, brandValue}
        // {label, value} format is necessary for async control
        asyncDataSet = await fetchOptionsById(formValue);
        if (optionTypeFlatArray) {
          asyncDataSet = asyncDataSet.reduce(
            (acc, item) => [...acc, { label: item, value: item }],
            [],
          );
        } else {
          asyncDataSet = optionMapper(
            asyncDataSet,
            optionLabelKey,
            optionValueKey,
          );
        }
      }
      setDataSet(asyncDataSet);
      setInitialized(true);
    }

    getDataSet();
  }, []);

  const effectProps = effectFunctions
    ? applyEffects(effects, effectFunctions)
    : null;

  return (
    <SelectController
      async
      initialized={initialized}
      control={control}
      name={name}
      dataSet={dataSet}
      optionsAttribute={optionsAttribute}
      fullWidth={fullWidth}
      label={label}
      localeId={localeId}
      hasConstantValue={hasConstantValue}
      formMode={formMode}
      helperText={helperText}
      isClearable={isClearable}
      isMulti={isMulti}
      loadOptions={fetchOptions}
      optionMapper={optionMapper}
      optionLabelKey={optionLabelKey}
      optionValueKey={optionValueKey}
      optionTypeFlatArray={optionTypeFlatArray}
      placeholder={formatMessage({
        id: 'SELECT_ASYNC_DROPDOWN',
        defaultMessage: 'Select or Type',
      })}
      {...rest}
      effectProps={effectProps}
    />
  );
}

export default Select;
