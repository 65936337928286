import { Badge, Box, Typography, styled } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

const border = '23px';

const StepperContainer = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.palette.secondary.main}`,
  overflow: 'auto',
  margin: '0',
  padding: '0',
  borderRadius: '15px',
  background: `${theme.palette.secondary.main}`,
  '& button': {
    margin: 0,
    padding: 0,
    border: 0,
    cursor: 'pointer',
    justifyContent: 'center',
  },
  overflowX: 'hidden',
}));

const Stepper = styled('div')(({ theme }) => ({
  padding: '0',
  clear: 'both',
  margin: '0 -1px',
  display: 'flex',
  flexDirection: 'row',
  flexWap: 'wrap',
  alignItems: 'center',
  justifyContent: 'center',
  '& button': {
    display: 'inline-flex',
    flex: 1,
    padding: '11px 0px 11px 30px',
    background: `${theme.palette.secondary.light}`,
    position: 'relative',
    margin: '0 1px',
    color: `${theme.palette.tertiary.main}`,
    textTransform: 'none',
    borderRadius: 0,
    ':first-of-type': {
      borderTopLeftRadius: '15px',
      borderBottomLeftRadius: '15px',
      ':before': {
        border: 0,
      },
    },
    ':last-child': {
      paddingRight: '30px',
    },
    ':hover': {
      background: `${theme.palette.primary.main}`,
      color: '#ffffff',
      '& .MuiSvgIcon-root': {
        color: '#ffffff',
      },
    },
    ':hover:after': {
      borderLeftColor: `${theme.palette.primary.main}`,
    },
    ':before': {
      content: '""',
      borderLeft: `${border} solid ${theme.palette.secondary.main}`,
      borderTop: `${border} solid transparent`,
      borderBottom: `${border} solid transparent`,
      position: 'absolute',
      top: '0',
      left: '0',
    },
    ':after': {
      content: "''",
      borderLeft: `${border} solid ${theme.palette.secondary.light}`,
      borderTop: `${border} solid transparent`,
      borderBottom: `${border} solid transparent`,
      position: 'absolute',
      top: '0',
      left: '100%',
      zIndex: '20',
    },
    '&.active': {
      background: `${theme.palette.primary.main}`,
      color: `#ffffff`,
      ':after': {
        borderLeftColor: `${theme.palette.primary.main}`,
      },
      '& .icon': {
        color: `#ffffff`,
      },
    },
    '& .icon': {
      color: `${theme.palette.tertiary.main}`,
    },
  },
}));

const AdjustedBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    right: -12,
    top: 5,
    fontSize: 11,
    height: 17,
    minWidth: 17,
    zIndex: 30,
  },
}));

function convertToUIFieldName(inputString) {
  // Use regular expression to replace the numeric index with empty brackets []
  const resultString = inputString.replace('$.', '').replace(/\[\d+\]/g, '[]');
  return resultString;
}

function Step({
  Icon,
  label,
  errorCount = 0,
  data = {},
  index,
  onClick,
  ...rest
}) {
  return (
    <button type="button" onClick={() => onClick(index, data)} {...rest}>
      <AdjustedBadge
        badgeContent={errorCount}
        {...(errorCount > 0 && { color: 'warning' })}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}>
          <Icon className="icon" sx={{ width: '20px' }} />
          <Typography
            className="text"
            sx={{ ml: '5px', whiteSpace: 'nowrap' }}
            variant="subtitle2">
            {label}
          </Typography>
        </Box>
      </AdjustedBadge>
    </button>
  );
}

function ArrowStepper({
  steps = [],
  activeStep = 0,
  onChange = () => undefined,
  sectionMapping = {},
  errors = [],
  containerId = 'stickyHeaderContainer',
}) {
  const containerRef = useRef(null);
  const [currentStep, setCurrentStep] = useState(activeStep);
  const [currentErrors, setCurrentErrors] = useState([]);

  const getErrorCounts = () =>
    errors.reduce((acc, errorItem) => {
      if (errorItem.path) {
        const fieldName = convertToUIFieldName(errorItem.path);
        const sectionName = sectionMapping[fieldName];
        if (sectionName) {
          if (!Object.prototype.hasOwnProperty.call(acc, sectionName)) {
            return {
              ...acc,
              [sectionName]: 1,
            };
          }
          acc[sectionName] += 1;
          return acc;
        }
      }
      return acc;
    }, {});

  const getOffsetBottom = id => {
    const element = document.getElementById(id);
    return (element?.offsetHeight || 0) + (element?.offsetTop || 0);
  };

  const highlightStep = () => {
    const tolerance = 50;
    if (containerRef.current) {
      const stepperBottomLeft = getOffsetBottom(containerId);

      steps.every((item, index) => {
        const itemCurrentBottom =
          getOffsetBottom(item.id) - window.scrollY - tolerance;

        if (itemCurrentBottom > stepperBottomLeft) {
          setCurrentStep(index);
          return false;
        }
        return true;
      });
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', highlightStep);

    return () => window.removeEventListener('scroll', highlightStep);
  }, []);

  useEffect(() => {
    const errorCounts = getErrorCounts();
    setCurrentErrors(errorCounts);
  }, [JSON.stringify(errors)]);

  return (
    <StepperContainer ref={containerRef}>
      <Stepper>
        {steps.map((item, index) => {
          const className = currentStep === index ? 'active' : '';
          return (
            <Step
              key={item.label}
              className={className}
              Icon={item.icon}
              label={item.label}
              data={item}
              index={index}
              errorCount={currentErrors[item.id] || 0}
              onClick={(stepIndex, stepData) => {
                setCurrentStep(stepIndex);
                onChange(stepIndex, stepData);
                const changeExpanded = window.sxclPageSections.get(stepData.id);
                changeExpanded(true);
              }}
            />
          );
        })}
      </Stepper>
    </StepperContainer>
  );
}

export default ArrowStepper;
