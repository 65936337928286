/*
 * Api domain differs for localhost, but is the same for AWS Environments
 */
function getDomain(postfix = 'api', forApi = true) {
  if (process.env.REACT_APP_API_HOST && forApi) {
    if (postfix) {
      return `${process.env.REACT_APP_API_HOST}/${postfix}`;
    }
    return `${process.env.REACT_APP_API_HOST}`;
  }
  const { protocol, port, hostname } = window.location;
  const fullDomainPath = `${protocol}//${hostname}${port ? `:${port}` : ''}`;
  return postfix ? `${fullDomainPath}/${postfix}` : fullDomainPath;
}

export default getDomain;
