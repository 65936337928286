import * as Scroll from 'react-scroll';
import { createContext, useContext, useMemo } from 'react';

export const ScrollingContext = createContext({
  scoller: null,
});

function ScrollingProvider({ children }) {
  const value = useMemo(
    () => ({
      scroller: Scroll.scroller,
    }),
    [Scroll],
  );
  return (
    <ScrollingContext.Provider value={value}>
      {children}
    </ScrollingContext.Provider>
  );
}

export const useScrolling = () => {
  const scrollContext = useContext(ScrollingContext);
  return scrollContext;
};

export default ScrollingProvider;
