import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/material-icons';

import { createTheme } from '@mui/material/styles';

const borderRadius = 10;
const pageBackgroundColor = '#f8f8f8';

export const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      dark: '#0076a7',
      main: '#009ACD',
      light: '#CCEBF5',
      contrastText: '#ffffff',
    },
    secondary: {
      dark: '#7e7f80',
      main: '#D7D7D7',
      light: '#EAEBEC',
      contrastText: '#000000',
    },
    tertiary: {
      main: '#000000',
      contrastText: '#ffffff',
    },
    success: {
      main: '#2E7D32',
      light: '#4CAF50',
      dark: '#1B5E20',
    },
    background: {
      default: '#ffffff',
      paper: '#ffffff',
      content: pageBackgroundColor,
    },
  },
  shape: {
    borderRadius,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        body {
          background: ${pageBackgroundColor};
        }
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0; 
        }
        input[type=number] {
            -moz-appearance:textfield;
        }

        pre {
          white-space: pre-wrap;       /* Since CSS 2.1 */
          white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
          white-space: -pre-wrap;      /* Opera 4-6 */
          white-space: -o-pre-wrap;    /* Opera 7 */
          word-wrap: break-word;       /* Internet Explorer 5.5+ */
      }

        .MuiInputBase-root {
          background: #ffffff;
        }
        .MuiFormControl-root .MuiFormLabel-root{
          transform: none;
          position: static;
          margin-bottom: 8px;
        }
        .MuiFormLabel-root{
          font-weight: 500 !important;
          font-size: 0.87rem !important;
        }
        .MuiFormControl-root legend span{
          display: none;
        }
        .MuiFormControl-root .MuiFormHelperText-root.Mui-error{
          font-size: 0.85rem;
        }
        .Mui-datepicker .MuiFormControl-root fieldset{
          top:0 !important;
        }
        .MuiInputBase-root.MuiInputBase-adornedStart{
          padding-left: 6px;
        }
        .MuiInputAdornment-root.MuiInputAdornment-positionStart{
          margin-right: 3px;
        }
        .MuiButton-startIcon{
          margin-right: 2px !important;
        }
        .MuiButton-startIcon svg{
          width:0.8em;
        }
        .MuiButtonBase-root.MuiTab-root{
          text-transform: none;
        }
        .MuiOutlinedInput-root.Mui-disabled{
          opacity:0.5;
        }
      `,
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
    },
  },
});

export const darkTheme = createTheme({
  ...lightTheme,
});
