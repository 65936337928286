import {
  FormControl,
  InputLabel,
  FormHelperText,
  useMediaQuery,
} from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { Controller } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { DYNAMIC_FORM_MODE } from '../DynamicForm/lib/constants';
import SelectComponent from './SelectComponent';
import FormValue from '../FormValue';

function SelectController({
  setValue,
  initialized,
  control,
  name,
  dataSet,
  optionsAttribute,
  fullWidth,
  label,
  localeId = null,
  hasConstantValue,
  formMode,
  helperText,
  async,
  isClearable,
  isMulti,
  loadOptions,
  optionMapper,
  optionLabelKey,
  optionValueKey,
  optionTypeFlatArray = false,
  effectProps = null,
  selectFirst = false,
  ...rest
}) {
  const [focused, setFocused] = useState(false);
  const [isConstant, setIsConstant] = useState(hasConstantValue);

  const { disabled = false } = effectProps || { disabled: false };
  const isMobile = useMediaQuery('(max-width:500px)');
  useEffect(() => {
    if (selectFirst) {
      if (Array.isArray(dataSet) && dataSet.length === 1) {
        setValue(name, dataSet[0][optionValueKey]);
        setIsConstant(true);
      }
    }
  }, []);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field = {}, fieldState = {} }) => {
        const { onChange, value, ...hookFormProps } = field;

        const dataField = dataSet?.find(
          item => item[optionsAttribute.value] === value,
        );

        return (
          <FormControl
            fullWidth={fullWidth}
            sx={
              {
                // width: isMobile ? '18rem' : 'auto',
              }
            }>
            <InputLabel
              focused={!!focused}
              {...(fieldState?.error?.message && {
                error: !!fieldState?.error?.message,
              })}
              sx={{ mt: isMobile ? 1 : 0.5 }}>
              <FormattedMessage id={localeId} defaultMessage={label} />
            </InputLabel>
            {isConstant || formMode === DYNAMIC_FORM_MODE.VIEW || disabled ? (
              <div>
                <FormValue>{dataField?.label || ''}</FormValue>
              </div>
            ) : (
              <SelectComponent
                initialized={initialized}
                name={name}
                isClearable={isClearable}
                setFocused={setFocused}
                dataSet={dataSet}
                optionsAttribute={optionsAttribute}
                isMulti={isMulti}
                value={value}
                onChange={onChange}
                async={async}
                optionMapper={optionMapper}
                optionLabelKey={optionLabelKey}
                optionValueKey={optionValueKey}
                loadOptions={loadOptions}
                hookFormProps={hookFormProps}
                optionTypeFlatArray={optionTypeFlatArray}
                {...rest}
                {...effectProps}
              />
            )}
            <FormHelperText
              {...(fieldState?.error?.message && {
                error: !!fieldState?.error?.message,
              })}>
              {fieldState?.error?.message
                ? fieldState?.error?.message
                : helperText}
            </FormHelperText>
          </FormControl>
        );
      }}
    />
  );
}

export default SelectController;
