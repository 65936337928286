import dayjs from 'dayjs';
import { LocalizationProvider as LocalizationProviderMui } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/en';

function LocalizationProvider({ children, adapterLocale = 'en' }) {
  return (
    <LocalizationProviderMui
      dateAdapter={AdapterDayjs}
      adapterLocale={adapterLocale}
      dateLibInstance={dayjs}>
      {children}
    </LocalizationProviderMui>
  );
}

export default LocalizationProvider;
