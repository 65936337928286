import { PageLoading } from '@onpointwarranty/claims-design-system';
import { FormattedMessage } from 'react-intl';

export const ROUTE_LOADING_TYPE = {
  ROUTE_LAZY_LOADING: 'ROUTE_LAZY_LOADING',
  ROUTE_PERMISSION_CHECK: 'ROUTE_PERMISSION_CHECK',
};

function RouteLoading({ type = ROUTE_LOADING_TYPE.ROUTE_LAZY_LOADING }) {
  switch (type) {
    case ROUTE_LOADING_TYPE.ROUTE_PERMISSION_CHECK:
      return (
        <PageLoading>
          <FormattedMessage
            id="CHECKING_PERMISSIONS"
            defaultMessage="Checking Permissions..."
          />
        </PageLoading>
      );
    default:
      return (
        <PageLoading>
          <FormattedMessage
            id="LOADING_PAGE"
            defaultMessage="Loading Page..."
          />
        </PageLoading>
      );
  }
}

export default RouteLoading;
