import { styled } from '@mui/material';
import Sticky from 'react-stickynode';

const StyledSticky = styled(Sticky)({
  '& .smooth': {
    transition: 'all .5s ease !important',
  },
});

/*
  Params list at https://github.com/yahoo/react-stickynode/tree/master
  innerClass="smooth"
  innerZ={20}
  enabled
  top={5}
  bottomBoundary={1200}
*/

function StickySection({ children, ...props }) {
  return <StyledSticky {...props}>{children}</StyledSticky>;
}

export default StickySection;
