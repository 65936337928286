import {
  useSnackbar as useSnackbarMui,
  SnackbarProvider as MuiSnackbarProvider,
} from 'material-ui-snackbar-provider';
import { Alert, Button, Snackbar } from '@mui/material';

function CustomSnackbar({
  message = { type: 'success', data: '' },
  action,
  ButtonProps,
  SnackbarProps,
}) {
  return (
    <Snackbar autoHideDuration={3000} {...SnackbarProps}>
      <Alert
        severity={message?.type || 'success'}
        variant="filled"
        action={
          action != null && (
            <Button color="inherit" size="small" {...ButtonProps}>
              {action}
            </Button>
          )
        }>
        {message?.data || message}
      </Alert>
    </Snackbar>
  );
}

function SnackbarProvider({ children, SnackbarProps }) {
  return (
    <MuiSnackbarProvider
      SnackbarProps={SnackbarProps}
      SnackbarComponent={CustomSnackbar}>
      {children}
    </MuiSnackbarProvider>
  );
}

export function useSnackbar() {
  const context = useSnackbarMui();
  return context;
}

export default SnackbarProvider;
