import { makeAutoObservable } from 'mobx';
import { clearPersistedStore, makePersistable } from 'mobx-persist-store';

class CreateClaimModalStore {
  open = false;

  constructor(rootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this);

    makePersistable(this, {
      name: 'CreateClaimModalStore',
      properties: ['open'],
    });
  }

  setOpen(open) {
    this.open = open;
  }

  async clearStore() {
    await clearPersistedStore(this);
  }
}

export default CreateClaimModalStore;
