import { createContext, useContext } from 'react';
import localForage from 'localforage';
import { configurePersistable } from 'mobx-persist-store';

configurePersistable({
  storage: localForage,
  stringify: true,
  debugMode: false,
});

const MobxContext = createContext({});

function MobxProvider({ children, mobxRootStore }) {
  return (
    <MobxContext.Provider value={mobxRootStore}>
      {children}
    </MobxContext.Provider>
  );
}

export const useStore = () => {
  const storeContext = useContext(MobxContext);

  if (!storeContext) {
    throw new Error('useStore must be used within a StoreProvider.');
  }

  return storeContext;
};

export default MobxProvider;
