import { makeAutoObservable } from 'mobx';
import { clearPersistedStore, makePersistable } from 'mobx-persist-store';

class ClaimsStore {
  prospectiveClaims = {};

  claims = {};

  constructor(rootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this);

    makePersistable(this, {
      name: 'ClaimsStore',
      properties: ['prospectiveClaims', 'claims'],
    });
  }

  setClaim(claimId, value) {
    this.claims[claimId] = value;
  }

  deleteClaim(claimId) {
    delete this.claims[claimId];
  }

  getClaim(claimId) {
    return this.claims[claimId];
  }

  setProspectiveClaim(claimId, value) {
    this.prospectiveClaims[claimId] = value;
  }

  deleteProspectiveClaim(claimId) {
    delete this.prospectiveClaims[claimId];
  }

  getProspectiveClaim(claimId) {
    return this.prospectiveClaims[claimId];
  }

  async clearStore() {
    await clearPersistedStore(this);
  }
}

export default ClaimsStore;
