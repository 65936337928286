import {
  Box,
  Checkbox,
  IconButton,
  Slide,
  Stack,
  Tooltip,
  styled,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import {
  CompareArrows,
  FilterAlt,
  FilterAltOutlined,
  GridOnOutlined,
  SettingsOverscan,
} from '@mui/icons-material';
import { useRef, useState } from 'react';

const StyledCheckedBox = styled(Checkbox)(({ theme, checked }) => ({
  background: checked ? theme.palette.primary.light : 'auto',
  padding: '5px',
  '&:hover': {
    background: theme.palette.primary.light,
  },
}));

const StyledTableControlButton = styled(IconButton, {
  shouldForwardProp: props => !['show'].includes(props),
})(({ theme, show }) => ({
  backgroundColor: theme.palette.secondary.light,
  '&:hover': {
    background: theme.palette.secondary.light,
  },
  transform: show ? 'rotate(-45deg)' : 'rotate(0deg)',
  transition: 'transform 0.4s',
  padding: '9px',
}));

function TableControls({
  resize = false,
  move = false,
  filter = false,
  onChange = () => undefined,
  ...rest
}) {
  const containerRef = useRef(null);
  const [show, setShow] = useState(false);
  const [control, setControl] = useState({
    resize,
    move,
    filter,
  });

  const items = {
    resize: {
      icon: SettingsOverscan,
      checkedIcon: SettingsOverscan,
      tooltip: 'Resize Columns',
      checked: control.resize,
    },
    filter: {
      icon: FilterAltOutlined,
      checkedIcon: FilterAlt,
      tooltip: 'Show Filters',
      checked: control.filter,
    },
    move: {
      icon: CompareArrows,
      checkedIcon: CompareArrows,
      tooltip: 'Move Columns',
      checked: control.move,
    },
  };

  return (
    <Grid2 container {...rest}>
      <Grid2>
        <Grid2
          ref={containerRef}
          sx={{
            display: 'inline-flex',
            verticalAlign: 'middle',
            overflow: 'hidden',
          }}>
          <Slide
            direction="left"
            in={show}
            mountOnEnter
            unmountOnExit
            container={containerRef.current}>
            <Box>
              <Stack sx={{ mr: 1 }} direction="row" spacing={1}>
                {Object.entries(items).map(([key, item]) => (
                  <Tooltip key={key} placement="top" arrow title={item.tooltip}>
                    <StyledCheckedBox
                      icon={<item.icon sx={{ fontSize: '20px' }} />}
                      checkedIcon={
                        <item.checkedIcon sx={{ fontSize: '20px' }} />
                      }
                      checked={control[key]}
                      onChange={(e, checked) => {
                        setControl({
                          ...control,
                          [key]: checked,
                        });
                        onChange(key, checked);
                      }}
                    />
                  </Tooltip>
                ))}
              </Stack>
            </Box>
          </Slide>
        </Grid2>
        <Tooltip placement="top" arrow title="Data Table Controls">
          <StyledTableControlButton
            show={show}
            size="small"
            color="secondary.main"
            onClick={() => setShow(!show)}>
            <GridOnOutlined sx={{ fontSize: '20px' }} />
          </StyledTableControlButton>
        </Tooltip>
      </Grid2>
    </Grid2>
  );
}

export default TableControls;
