import {
  Box,
  Drawer as MuiDrawer,
  IconButton,
  styled,
  useTheme,
} from '@mui/material';
import { cloneElement } from 'react';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

const drawerWidth = 240;

const StyledIconButton = styled(IconButton, {
  shouldForwardProp: props => !['collapsed'].includes(props),
})(({ theme, collapsed = false }) => ({
  boxShadow: theme.shadows[1],
  borderRadius: '8px',
  width: '32px',
  height: '32px',
  right: collapsed ? '-90%' : '-98%',
  top: '30px',
  background: '#ffffff',
  ':hover': {
    background: theme.palette.secondary.light,
  },
}));

const openedMixin = theme => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'visible',
});

const closedMixin = theme => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'visible',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

function CloseButton({ collapsed = false, onClick = () => undefined }) {
  const theme = useTheme();

  return (
    <StyledIconButton
      onClick={onClick}
      collapsed={collapsed}
      variant="outlined">
      {!collapsed ? (
        <NavigateBeforeIcon sx={{ color: theme.palette.tertiary.main }} />
      ) : (
        <NavigateNextIcon sx={{ color: theme.palette.tertiary.main }} />
      )}
    </StyledIconButton>
  );
}

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

function MainMenuSidebar({
  children,
  open = false,
  setOpen = () => undefined,
}) {
  return (
    <Drawer
      PaperProps={{
        sx: {
          overflow: 'visible',
          zIndex: '100 !important',
        },
      }}
      variant="permanent"
      onClose={() => {
        setOpen(false);
      }}
      anchor="left"
      open={open}>
      <CloseButton
        onClick={() => {
          setOpen(!open);
        }}
        collapsed={!open}
      />
      <Box>{cloneElement(children, { open })}</Box>
    </Drawer>
  );
}

export default MainMenuSidebar;
