import { Controller } from 'react-hook-form';

function HiddenFormControl(props) {
  const {
    control,
    name,
    containerComponent,
    jsonSchema,
    masterData,
    formId,
    dataType,
    ...hiddenFieldProps
  } = props;

  return (
    <Controller
      control={control}
      name={name}
      {...props}
      render={data => (
        <input type="hidden" {...data.field} {...hiddenFieldProps} />
      )}
    />
  );
}

export default HiddenFormControl;
