import { DATE_FORMAT } from '@onpointwarranty/claims-design-system';

const MAX_NOTE_LENGTH = 1000;

const COMPANY_TYPE = {
  SERVICE_ADMINISTRATOR: 'SERVICE_ADMINISTRATOR',
  SERVICE_PROVIDER: 'SERVICE_PROVIDER',
};

const PERMISSIONS = {
  ADJUDICATE_CLAIMS: 'ADJUDICATE_CLAIMS',
  MODIFY_CLAIMS: 'MODIFY_CLAIMS',
  UNDERWRITER_APPROVE: 'UNDERWRITER_APPROVE',
  UNDERWRITER_REJECT: 'UNDERWRITER_REJECT',
};

const CLAIM_STATUS = {
  INC: 'INC',
  CAN: 'CAN',
  SUB: 'SUB',
  PAR: 'PAR',
  PUR: 'PUR',
  PWR: 'PWR',
  COR: 'COR',
  APP: 'APP',
  REJ: 'REJ',
  PAD: 'PAD',
  TRX: 'TRX',
};

const NOTE_ACTION = {
  EDIT: 'EDIT',
  ADD: 'ADD',
};

const SUPPORTED_NOTES_ATTACHMENT_FILETYPES = {
  'application/pdf': ['.pdf'],
  'image/jpeg': ['.jpeg'],
  'image/jpg': ['.jpg'],
  'image/png': ['.png'],
  'image/gif': ['.gif'],
  'application/msword': ['.doc'],
  'text/plain': ['.txt'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
    '.docx',
  ],
};

const SESSION_STORAGE_KEYCLOAK_CONFIG = 'KEYCLOAK_CONFIG';

const FIELD_TYPES = {
  AMOUNT: 'AMOUNT',
  PHONE: 'PHONE',
  PHONE_TYPE: 'PHONE_TYPE',
  DATE: 'DATE',
};

const AUDIT_FIELD_MAPPER = {
  amountApproved: {
    id: 'AMOUNT_APPROVED',
    type: FIELD_TYPES.AMOUNT,
  },
  customerEmailAddress: {
    id: 'CUSTOMER_EMAIL_ADDRESS',
  },
  'LABOR.amountApproved': {
    id: 'LABOR_AMOUNT_APPROVED',
    type: FIELD_TYPES.AMOUNT,
  },
  'PARTS_TOTAL.amountApproved': {
    id: 'PARTS_TOTAL_AMOUNT_APPROVED',
    type: FIELD_TYPES.AMOUNT,
  },
  'PARTS_HANDLING.amountApproved': {
    id: 'PARTS_HANDLING_AMOUNT_APPROVED',
    type: FIELD_TYPES.AMOUNT,
  },
  'TRAVEL.amountApproved': {
    id: 'TRAVEL_AMOUNT_APPROVED',
    type: FIELD_TYPES.AMOUNT,
  },
  'TAX.amountApproved': {
    id: 'TAX_AMOUNT_APPROVED',
    type: FIELD_TYPES.AMOUNT,
  },
  'FREIGHT_AND_SHIPPING.amountApproved': {
    id: 'FREIGHT_AND_SHIPPING_AMOUNT_APPROVED',
    type: FIELD_TYPES.AMOUNT,
  },
  'OTHER.amountApproved': {
    id: 'OTHER_AMOUNT_APPROVED',
    type: FIELD_TYPES.AMOUNT,
  },
  customerNameFirst: {
    id: 'CUSTOMER_NAME_FIRST',
  },
  customerNameLast: {
    id: 'CUSTOMER_NAME_LAST',
  },
  customerPhone: {
    id: 'CUSTOMER_PHONE',
    type: FIELD_TYPES.PHONE,
    format: 'US',
  },
  customerPhoneType: {
    id: 'CUSTOMER_PHONE_TYPE',
    type: FIELD_TYPES.PHONE_TYPE,
  },
  serviceAdministratorServiceContractNumber: {
    id: 'SERVICE_ADMINISTRATOR_SERVICE_CONTRACT_NUMBER',
  },
  authorizationAddedDay: {
    id: 'AUTHORIZATION_ADDED_DAY',
    type: FIELD_TYPES.DATE,
    format: DATE_FORMAT.MEDIUM_DATE,
  },
  claimReceivedDay: {
    id: 'CLAIM_RECEIVED_DAY',
    type: FIELD_TYPES.DATE,
    format: DATE_FORMAT.MEDIUM_DATE,
  },
  paymentDay: {
    id: 'PAYMENT_DAY',
    type: FIELD_TYPES.DATE,
    format: DATE_FORMAT.MEDIUM_DATE,
  },
  serviceStartDay: {
    id: 'SERVICE_START_DAY',
    type: FIELD_TYPES.DATE,
    format: DATE_FORMAT.MEDIUM_DATE,
  },
  amountRequested: {
    id: 'TOTAL_AMOUNT_REQUESTED',
    type: FIELD_TYPES.AMOUNT,
  },
  'LABOR.amountRequested': {
    id: 'LABOR_AMOUNT_REQUESTED',
    type: FIELD_TYPES.AMOUNT,
  },
  'PARTS_TOTAL.amountRequested': {
    id: 'PARTS_TOTAL_AMOUNT_REQUESTED',
    type: FIELD_TYPES.AMOUNT,
  },
  'PARTS_HANDLING.amountRequested': {
    id: 'PARTS_HANDLING_AMOUNT_REQUESTED',
    type: FIELD_TYPES.AMOUNT,
  },
  'TRAVEL.amountRequested': {
    id: 'TRAVEL_AMOUNT_REQUESTED',
    type: FIELD_TYPES.AMOUNT,
  },
  'TAX.amountRequested': {
    id: 'TAX_AMOUNT_REQUESTED',
    type: FIELD_TYPES.AMOUNT,
  },
  'FREIGHT_AND_SHIPPING.amountRequested': {
    id: 'FREIGHT_AND_SHIPPING_AMOUNT_REQUESTED',
    type: FIELD_TYPES.AMOUNT,
  },
  'OTHER.amountRequested': {
    id: 'OTHER_AMOUNT_REQUESTED',
    type: FIELD_TYPES.AMOUNT,
  },
  serialNumber: {
    id: 'SERIAL_NUMBER',
  },
  explanationOfServicePerformed: {
    id: 'EXPLANATION_OF_SERVICE_PERFORMED',
  },
  contractPurchaseDate: {
    id: 'CONTRACT_PURCHASE_DATE',
    type: FIELD_TYPES.DATE,
    format: DATE_FORMAT.MEDIUM_DATE,
  },
  preauthorizationNumber: {
    id: 'PREAUTHORIZATION_NUMBER',
  },
  repairCode: {
    id: 'REPAIR_CODE',
  },
  serviceProviderServiceContractNumber: {
    id: 'SERVICE_PROVIDER_SERVICE_CONTRACT_NUMBER',
  },
  brandId: {
    id: 'BRAND_ID',
  },
  modelNumber: {
    id: 'MODEL_NUMBER',
  },
  customerComplaint: {
    id: 'CUSTOMER_COMPLAINT',
  },
  serviceRequestedDate: {
    id: 'SERVICE_REQUESTED_DATE',
    type: FIELD_TYPES.DATE,
    format: DATE_FORMAT.MEDIUM_DATE,
  },
  serviceCompletionDate: {
    id: 'SERVICE_COMPLETION_DATE',
    type: FIELD_TYPES.DATE,
    format: DATE_FORMAT.MEDIUM_DATE,
  },
  claimNumberExt: {
    id: 'CLAIM_NUMBER_EXT',
  },
  complaintCode: {
    id: 'COMPLAINT_CODE',
  },
  contractEndDate: {
    id: 'CONTRACT_END_DATE',
    type: FIELD_TYPES.DATE,
    format: DATE_FORMAT.MEDIUM_DATE,
  },
  contractStartDate: {
    id: 'CONTRACT_START_DATE',
    type: FIELD_TYPES.DATE,
    format: DATE_FORMAT.MEDIUM_DATE,
  },
  customerAddressStreet: {
    id: 'CUSTOMER_ADDRESS_STREET',
  },
  customerAddressCity: {
    id: 'CUSTOMER_ADDRESS_CITY',
  },
  customerAddressState: {
    id: 'CUSTOMER_ADDRESS_STATE',
  },
  customerAddressPostalCode: {
    id: 'CUSTOMER_ADDRESS_POSTAL_CODE',
  },
  serviceSiteCodeId: {
    id: 'SERVICE_SITE_CODE_ID',
  },
  customerPhoneAlternate: {
    id: 'CUSTOMER_PHONE_ALTERNATE',
    type: FIELD_TYPES.PHONE,
    format: 'US',
  },
  customerCompanyName: {
    id: 'COMPANY_NAME',
  },
  serviceJobId: {
    id: 'SERVICE_JOB_ID',
  },
  modelDescription: {
    id: 'MODEL_DESCRIPTION',
  },
  productPurchaseDate: {
    id: 'PURCHASE_DATE',
    type: FIELD_TYPES.DATE,
    format: DATE_FORMAT.MEDIUM_DATE,
  },
  warrantyExpirationDate: {
    id: 'WARRANTY_EXPIRATION_DATE',
    type: FIELD_TYPES.DATE,
    format: DATE_FORMAT.MEDIUM_DATE,
  },
  productDescription: {
    id: 'Product_Description',
  },
  technician: {
    id: 'TECHNICIAN',
  },
  failureDate: {
    id: 'FAILURE_DATE',
    type: FIELD_TYPES.DATE,
    format: DATE_FORMAT.MEDIUM_DATE,
  },
  'REFRIGERANT.amountApproved': {
    id: 'REFRIGERANT_AMOUNT',
    type: FIELD_TYPES.AMOUNT,
  },
  'REFRIGERANT_RECLAMATION.amountApproved': {
    id: 'REFRIGERANT_RECLAMATION_AMOUNT',
    type: FIELD_TYPES.AMOUNT,
  },
  rejectReasonCodeId: {
    id: 'REJECT_REASON_CODE_ID',
  },
  rejectReasonText: {
    id: 'REJECT_REASON_TEXT',
  },
  approveReasonText: {
    id: 'APPROVE_REASON_TEXT',
  },
};

const ATTACHMENT_TYPES = [
  {
    label: 'Proof Of Purchase',
    value: 'PROOF_OF_PURCHASE',
  },
  {
    label: 'Product Image',
    value: 'PRODUCT_IMAGE',
  },
  {
    label: 'Warranty Document',
    value: 'WARRANTY_DOCUMENT',
  },
  {
    label: 'Other',
    value: 'OTHER',
  },
];

const ATTACHMENT_PRIVACY = [
  {
    label: 'INTERNAL',
    value: 'Internal',
  },
  {
    label: 'EXTERNAL',
    value: 'External',
  },
];

export {
  COMPANY_TYPE,
  PERMISSIONS,
  CLAIM_STATUS,
  SESSION_STORAGE_KEYCLOAK_CONFIG,
  AUDIT_FIELD_MAPPER,
  FIELD_TYPES,
  NOTE_ACTION,
  SUPPORTED_NOTES_ATTACHMENT_FILETYPES,
  ATTACHMENT_TYPES,
  ATTACHMENT_PRIVACY,
  MAX_NOTE_LENGTH,
};
