import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  styled,
  Switch as SwitchMui,
  Typography,
} from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { DYNAMIC_FORM_MODE } from '../DynamicForm/lib/constants';
import FormValue from '../FormValue';

const IOSSwitch = styled(SwitchMui)(() => ({
  padding: '3.5px',
  '& .MuiSwitch-switchBase': {
    transitionDuration: '300ms',
  },
}));

function Switch(props) {
  return (
    <span>
      <IOSSwitch size="small" {...props} />
    </span>
  );
}

export function SwitchFormControl(props) {
  const {
    label = null,
    localeId = null,
    sideLabel = '',
    helperText,
    readOnly = false,
    value = false,
    formMode,
    containerComponent,
    jsonSchema,
    masterData,
    formId,
    dataType,
    ...rest
  } = props;

  let switchControl = (
    <div>
      <IOSSwitch size="small" {...rest} />
    </div>
  );

  if (sideLabel) {
    switchControl = (
      <Box sx={{ display: 'inline-flex', p: '6.5px 14px' }}>
        <Switch sx={{ mr: 1 }} {...rest} />
        <Typography component="span">{sideLabel}</Typography>
      </Box>
    );
  }

  if (readOnly || formMode === DYNAMIC_FORM_MODE.VIEW) {
    switchControl = (
      <div>
        <FormValue>{value ? 'Yes' : 'No'}</FormValue>
        <input type="hidden" name={props?.name || ''} value={value} />
      </div>
    );
  }

  return (
    <FormControl fullWidth>
      <InputLabel>
        <FormattedMessage id={localeId} defaultMessage={label} />
        &nbsp;
      </InputLabel>
      {switchControl}
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
}

export default Switch;
