/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { useState } from 'react';

function ReadMore({
  children,
  showReadLess = false,
  charsToShow = null,
  readMoreText = 'read more',
  showLessText = 'show less',
}) {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(charsToShow || false);

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  if (!text) {
    return '';
  }

  return (
    <Grid2 container>
      <Typography>{isReadMore ? text?.slice(0, charsToShow) : text}</Typography>
      {isReadMore && text.length > charsToShow && (
        <>
          ...
          <Link
            component="button"
            variant="body2"
            onClick={() => {
              toggleReadMore();
            }}>
            {readMoreText}
          </Link>
        </>
      )}
      {!isReadMore && text.length > charsToShow && showReadLess && (
        <Link
          component="button"
          variant="body2"
          onClick={() => {
            toggleReadMore();
          }}>
          {showLessText}
        </Link>
      )}
    </Grid2>
  );
}

export default ReadMore;
