const DateTimeUtil = {
  localToISOStartOfDay(localDate) {
    const dated = new Date(localDate);
    return this.dateTimeToISO(dated);
  },
  localToISOEndOfDay(localDate) {
    const dated = new Date(localDate);
    dated.setDate(dated.getDate() + 1);
    return this.dateTimeToISO(dated);
  },
  dateTimeToISO(dated) {
    // need to convert a local date to the ISO string with UTC offset
    // ref: https://stackoverflow.com/questions/7403486/add-or-subtract-timezone-difference-to-javascript-date
    const timeOffsetInMS = dated.getTimezoneOffset() * 60000;
    dated.setTime(dated.getTime() + timeOffsetInMS);
    return dated.toISOString();
  },
};

export default DateTimeUtil;
