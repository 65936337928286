import { useEffect, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import MenuItems from './MenuItems';
import MainMenuSidebar from './MainMenuSidebar';
/*
 * TODO: Still a buggy component written in hurry, need to fix logic and make code more readable
 */
function MainMenu({ menuItems, logoHref = null, logoTarget = '', setOpenNav }) {
  const [open, setOpen] = useState(true);
  const isMobile = useMediaQuery('(max-width:500px)');
  const isTab = useMediaQuery('(max-width:768px)');
  useEffect(() => {
    if ((isMobile && !open) || (isTab && !open)) {
      setOpenNav(open);
    }
    if ((isMobile && open) || (isTab && open)) {
      // return open;
    }
  }, [open]);
  return (
    <MainMenuSidebar open={open} setOpen={setOpen}>
      <MenuItems
        logoHref={logoHref}
        logoTarget={logoTarget}
        menuItems={menuItems}
        collapsed={!open}
        setCollapse={collapse => setOpen(!collapse)}
      />
    </MainMenuSidebar>
  );
}

export default MainMenu;
