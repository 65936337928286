/* eslint-disable no-nested-ternary */
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { nanoid } from 'nanoid';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
// eslint-disable-next-line import/no-cycle
import renderComponents from '../DynamicForm/lib/renderComponents';
import { DYNAMIC_FORM_MODE } from '../DynamicForm/lib/constants';

// this component is created for Web View and Mobile View separately if you want to change anything change both side

function SimpleTable(props) {
  const {
    hasHeaderRow = false,
    control,
    formId,
    name,
    formMode = DYNAMIC_FORM_MODE.VIEW,
    helperText,
    label,
    rows = [],
    jsonSchema = {},
    masterData,
    hasConstantValue,
    additionalComponents = {},
    containerComponent,
    effectFunctions = null,
    formatMessage,
    setValue,
    dataType,
    columnsIndexToHide = [],
    rowsIndexToHide = [],
    getValues,
    itemsArrayIndex,
    additionalComponentKey,
    ...simpleTableProps
  } = props;

  const isMobile = useMediaQuery('(max-width:500px)');
  const isTab = useMediaQuery('(max-width:768px)');
  const HeaderRow = hasHeaderRow ? rows[0].data : null;
  const ContentRows = hasHeaderRow ? rows.slice(1) : rows;
  const ContentRows1ToMobile = hasHeaderRow ? rows[1].data : rows;
  const ContentRows2ToMobile = hasHeaderRow ? rows[2].data : rows;
  const ContentRows3ToMobile = hasHeaderRow ? rows[3].data : rows;
  const columnsIndexToHideInMobile = [columnsIndexToHide[0] * 2];
  const rowsIndexToHideInMobile = [];
  if (rowsIndexToHide.length > 0) {
    rowsIndexToHideInMobile.push(columnsIndexToHide[0] * 2);
  }

  // for Requested payment in Mobile
  const paymentDetailsForMobileView = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < HeaderRow.length; i++) {
    paymentDetailsForMobileView.push(HeaderRow[i]);
    paymentDetailsForMobileView.push(ContentRows1ToMobile[i]);
  }

  // for Approved payment  in Mobile
  const approvedDetailsForMobileView = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < HeaderRow.length; i++) {
    approvedDetailsForMobileView.push(HeaderRow[i]);
    approvedDetailsForMobileView.push(ContentRows2ToMobile[i]);
  }

  // for Authorized payment  in Mobile
  const AuthorizedDetailsForMobileView = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < HeaderRow.length; i++) {
    AuthorizedDetailsForMobileView.push(HeaderRow[i]);
    AuthorizedDetailsForMobileView.push(ContentRows3ToMobile[i]);
  }

  // web view
  const headers = HeaderRow.map((rowContentItem, colIndex) =>
    columnsIndexToHide.includes(colIndex) ? null : (
      <TableCell key={nanoid()}>
        {renderComponents({
          uiSchema: rowContentItem,
          additionalComponents,
          control,
          jsonSchema,
          formMode,
          masterData,
          formId,
          formatMessage,
        })}
        {rowContentItem['ui:props']?.toolTip?.message && (
          <Tooltip
            key={nanoid()}
            placement={rowContentItem['ui:props']?.toolTip?.position}
            arrow
            title={rowContentItem['ui:props']?.toolTip?.message}>
            <InfoIcon sx={{ marginBottom: '-6px' }} />
          </Tooltip>
        )}
      </TableCell>
    ),
  );

  return (
    <>
      {/* Web view   */}
      <TableContainer
        {...simpleTableProps}
        // eslint-disable-next-line no-nested-ternary
        sx={{ display: isMobile ? 'none' : isTab ? 'none' : 'block' }}>
        <Table style={{ width: '100%', display: isMobile ? 'none' : '' }}>
          {HeaderRow && (
            <TableHead>
              <TableRow key={nanoid()}>{headers}</TableRow>
            </TableHead>
          )}
          <TableBody>
            {ContentRows.map((rowItems, rowIndex) =>
              rowsIndexToHide.includes(rowIndex) ? null : (
                <TableRow
                  key={nanoid()}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    display: isMobile ? 'block' : '',
                  }}>
                  {rowItems.data.map((rowContentItem, colIndex) =>
                    columnsIndexToHide.includes(colIndex) ? null : (
                      <TableCell
                        key={nanoid()}
                        sx={{ display: isMobile ? 'block' : '' }}>
                        {renderComponents({
                          uiSchema: rowContentItem,
                          additionalComponents,
                          control,
                          jsonSchema,
                          formMode,
                          masterData,
                          formId,
                          effectFunctions,
                          formatMessage,
                          setValue,
                        })}
                      </TableCell>
                    ),
                  )}
                </TableRow>
              ),
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* to mobile screen */}
      {/* displayed only the mobile screen and also the Tab screen */}
      <Grid2
        xs={12}
        sx={{
          width: '100%',
          // eslint-disable-next-line no-nested-ternary
          display: isMobile ? 'block' : isTab ? 'block' : 'none',
        }}>
        <Grid2>
          <Grid2
            elevation={2}
            sx={{ marginBottom: '0.4rem', width: '100%' }}
            xs={12}
            mb={3}>
            {paymentDetailsForMobileView.map((rowContentItem, colIndex) =>
              columnsIndexToHideInMobile.includes(colIndex) ||
              rowsIndexToHideInMobile.includes(colIndex) ? null : (
                <Grid2 spacing={4}>
                  <Typography
                    key={nanoid()}
                    sx={{ marginBottom: isTab ? '0.5rem' : '' }}>
                    {renderComponents({
                      uiSchema: rowContentItem,
                      additionalComponents,
                      control,
                      jsonSchema,
                      formMode,
                      masterData,
                      formId,
                      effectFunctions,
                      formatMessage,
                      setValue,
                    })}
                    {rowContentItem['ui:props']?.toolTip?.message && (
                      <Typography sx={{ fontSize: '10px' }}>
                        (
                        {formMode === 'EDIT'
                          ? rowContentItem['ui:props']?.toolTip?.message
                          : formMode === 'CREATE'
                          ? rowContentItem['ui:props']?.toolTip?.message
                          : ''}
                        )
                      </Typography>
                    )}
                  </Typography>
                </Grid2>
              ),
            )}
          </Grid2>
          <hr />
          <Grid2
            elevation={2}
            sx={{ marginBottom: '0.5rem', width: '100%' }}
            xs={12}
            mb={3}>
            {approvedDetailsForMobileView.map((rowContentItem, colIndex) =>
              columnsIndexToHideInMobile.includes(colIndex) ||
              rowsIndexToHideInMobile.includes(colIndex) ? null : (
                <Grid2 spacing={4}>
                  <Typography
                    key={nanoid()}
                    sx={{
                      display: isMobile ? 'block' : '',
                      // marginBottom: '0.5rem',
                    }}>
                    {renderComponents({
                      uiSchema: rowContentItem,
                      additionalComponents,
                      control,
                      jsonSchema,
                      formMode,
                      masterData,
                      formId,
                      effectFunctions,
                      formatMessage,
                      setValue,
                    })}
                  </Typography>
                </Grid2>
              ),
            )}
            {/* </Typography>

             ),
            )} */}
          </Grid2>
          <hr />
          <Grid2
            elevation={2}
            sx={{ marginBottom: '0.5rem', width: '100%' }}
            xs={12}
            mb={1}>
            {/* {ContentRows1ToMobile.map((rowItems, rowIndex) =>
              rowsIndexToHide.includes(rowIndex) ? null : ( 
            <Typography
              key={nanoid()}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
                display: isMobile ? 'block' : '',
              }}> */}
            {AuthorizedDetailsForMobileView.map((rowContentItem, colIndex) =>
              columnsIndexToHideInMobile.includes(colIndex) ||
              rowsIndexToHideInMobile.includes(colIndex) ? null : (
                <Grid2 spacing={4}>
                  <Typography
                    key={nanoid()}
                    sx={{
                      display: isMobile ? 'block' : '',
                      // marginBottom: '2rem',
                    }}>
                    {renderComponents({
                      uiSchema: rowContentItem,
                      additionalComponents,
                      control,
                      jsonSchema,
                      formMode,
                      masterData,
                      formId,
                      effectFunctions,
                      formatMessage,
                      setValue,
                    })}
                    {/* {rowContentItem['ui:props']?.toolTip?.message && (
                      <Tooltip
                        key={nanoid()}
                        placement={
                          rowContentItem['ui:props']?.toolTip?.position
                        }
                        arrow
                        title={rowContentItem['ui:props']?.toolTip?.message}>
                        <InfoIcon sx={{ marginBottom: '-6px' }} />
                      </Tooltip>
                    )} */}
                  </Typography>
                </Grid2>
              ),
            )}
            {/* </Typography>

           ),
            )} */}
          </Grid2>
        </Grid2>
      </Grid2>
    </>
  );
}

export default SimpleTable;
