import { useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { useErrorBoundary } from 'react-error-boundary';
import { observer } from 'mobx-react';
import {
  useDataProvider,
  useStore,
} from '@onpointwarranty/claims-design-system';
import RouteLoading, {
  ROUTE_LOADING_TYPE,
} from '@components/Routing/RouteLoading';

function RouteRBACCheck({ children }) {
  const auth = useAuth();
  const { getOne } = useDataProvider();
  const { formatMessage } = useIntl();
  const { showBoundary } = useErrorBoundary();
  const [isCheckingRBAC, setIsCheckingRBAC] = useState(true);
  const [hasValidRBAC, setHasValidRBAC] = useState(false);
  const store = useStore();

  const getID = () => {
    let user = {};
    /* eslint no-underscore-dangle: 0 */
    user = auth?.settings?.userStore?._store['oidc.user'];
    if (user) {
      user = JSON.parse(user);
    }
    return user?.sub;
  };

  const userID = auth?.user?.profile?.sub || getID();

  useEffect(() => {
    async function checkAccess() {
      let userAccess = null;
      const accessDetermined = store.domain.userAccess.get(userID);
      if (!accessDetermined) {
        const accessFound = await getOne(`/claims/v1/permissions/user`);
        if (accessFound?.data) {
          store.domain.userAccess.set(userID, {
            ...accessFound.data,
          });
          userAccess = accessFound.data;
        } else {
          showBoundary({
            message: formatMessage({
              id: 'USER_PERMISSION_NOT_FOUND',
              defaultMessage: 'User permissions not found',
            }),
          });
        }
      } else {
        userAccess = accessDetermined;
      }

      const hasAccess = !!userAccess?.companyType;

      setIsCheckingRBAC(false);
      setHasValidRBAC(hasAccess);

      if (!hasAccess) {
        showBoundary({
          message: formatMessage({
            id: 'COULD_NOT_DETERMINE_USER_PERMISSIONS',
            defaultMessage: 'Could not determine user permissions',
          }),
        });
      }
    }
    checkAccess();
  }, []);

  if (isCheckingRBAC) {
    return <RouteLoading type={ROUTE_LOADING_TYPE.ROUTE_PERMISSION_CHECK} />;
  }

  if (hasValidRBAC) return children;

  return null;
}

export default observer(RouteRBACCheck);
