import { Box, styled } from '@mui/material';

const StyledBox = styled(Box)(({ theme }) => ({
  borderRadius: '20px',
  border: `1px solid ${theme.palette.secondary.main}`,
  padding: '20px 0px',
  '& ul': {
    marginBottom: '0px',
  },
}));

const BorderedBox = styled(Box)(() => ({
  borderLeft: `4px solid `,
  position: 'relative',
  left: '-3px',
  paddingLeft: '12px',
}));

function SpecialSection({ children, color = 'primary.main', ...rest }) {
  return (
    <StyledBox {...rest}>
      <BorderedBox sx={{ borderLeftColor: color }}>{children}</BorderedBox>
    </StyledBox>
  );
}

export default SpecialSection;
