class RootStore {
  constructor(uiStores = {}, domainStores = {}) {
    this.uiState = uiStores;
    this.domain = domainStores;
  }

  addUiStore(storeName, storeInstance) {
    this.uiState = {
      ...this.uiState,
      [storeName]: storeInstance,
    };
  }

  addDomainStore(storeName, storeInstance) {
    this.domain = {
      ...this.domain,
      [storeName]: storeInstance,
    };
  }

  async clearStoredData() {
    Object.entries(this.uiState).map(async ([, obj]) => obj.clearStore());
    Object.entries(this.domain).map(async ([, obj]) => obj.clearStore());
  }
}

export default RootStore;
