import {
  ListItemIcon,
  ListItemText,
  MenuItem as MuiMenuItem,
} from '@mui/material';

function MenuItem({
  Icon,
  IconProps = {},
  children,
  onClick = () => undefined,
  ...rest
}) {
  return (
    <MuiMenuItem onClick={onClick} {...rest}>
      <ListItemIcon>
        <Icon {...IconProps} />
      </ListItemIcon>
      <ListItemText>{children}</ListItemText>
    </MuiMenuItem>
  );
}

export default MenuItem;
