import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useState } from 'react';

export default function LanguageSelector({
  defaultLanguage = 'en',
  languagesSupported = [],
  onChange = () => undefined,
}) {
  const [language, setLanguage] = useState(defaultLanguage);

  const handleChange = (event, newLanguage) => {
    setLanguage(newLanguage);
    onChange(event, newLanguage);
  };

  if (languagesSupported?.length > 1) {
    return (
      <ToggleButtonGroup
        size="small"
        color="primary"
        value={language}
        exclusive
        onChange={handleChange}>
        {languagesSupported.map(item => (
          <ToggleButton key={item} value={item}>
            {item}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    );
  }
  return null;
}
