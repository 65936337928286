import {
  PageLoading,
  useDataProvider,
  useStore,
} from '@onpointwarranty/claims-design-system';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAuth } from 'react-oidc-context';
import { useSearchParams } from 'react-router-dom';

function AppDataProvider({ children }) {
  const store = useStore();
  const auth = useAuth();
  const { getOne } = useDataProvider();
  const [searchParams, setSearchParams] = useSearchParams();
  const [initializing, setInitializing] = useState(true);
  const [error, setError] = useState(false);

  const masterData = store.domain.masterData.get() || {};
  const supportedLanguages =
    store.domain.locale.getSupportedLanguages() || null;

  const currentSession = store.domain.masterData.getSession();

  const fetchAndSetLanguages = async () => {
    const data = await getOne(`/claims/v1/config`);
    store.domain.locale.setSupportedLanguages(data?.data?.languages || []);
  };

  const fetchAndSetMetaData = async () => {
    const result = await getOne('/claims/v1/claim/precreate/meta');
    const masterDataResult = result?.data || {};
    store.domain.masterData.set(masterDataResult);
  };

  const fetchAppData = async (forceReFetch = false) => {
    try {
      const hasCachedSupportedLanguages = supportedLanguages;

      if (!hasCachedSupportedLanguages || forceReFetch) {
        await fetchAndSetLanguages();
      }

      const hasCachedMasterData = Object.entries(masterData)?.length > 0;

      if (!hasCachedMasterData || forceReFetch) {
        await fetchAndSetMetaData();
      }

      setError(false);
    } catch (e) {
      setError(true);
    }
    setInitializing(false);
  };

  useEffect(() => {
    /*
     * Refetch logic will execute if user logs in
     */
    const refetchData =
      !currentSession || currentSession !== auth?.user?.session_state;

    if (refetchData) {
      store.domain.masterData.setSession(auth?.user?.session_state);
    }

    fetchAppData(refetchData);
  }, []);

  useEffect(() => {
    // remove params attached by keycloak in case on redirect uri is set on signin
    searchParams.delete('state');
    searchParams.delete('code');
    searchParams.delete('session_state');
    setSearchParams(searchParams.toString());
  }, []);

  if (initializing) {
    return (
      <PageLoading>
        <FormattedMessage
          id="LOAD_MASTER_DATA"
          defaultMessage="Loading Master Data"
        />
      </PageLoading>
    );
  }

  if (error) {
    return <p>Error fetching master data</p>;
  }

  return children;
}

export default AppDataProvider;
