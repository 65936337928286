const FormJsonSchemaUtil = {
  getLeafNodesMap: (nodes, result = new Map()) => {
    for (let i = 0, { length } = nodes; i < length; i += 1) {
      if (!nodes[i]['ui:children'] || nodes[i]['ui:children'].length === 0) {
        const fieldName = FormJsonSchemaUtil.getHtmlNameFromSchemaName({
          jsonSchemaName: nodes[i]['jsonschema:name'],
        });

        result.set(fieldName, nodes[i]);
      } else {
        // eslint-disable-next-line no-param-reassign
        result = FormJsonSchemaUtil.getLeafNodesMap(
          nodes[i]['ui:children'],
          result,
        );
      }
    }
    return result;
  },
  getValueFromObject: (obj, path) => {
    const paths = path.split('.');
    let current = obj;

    if (!obj) {
      return null;
    }

    for (let i = 0; i <= paths.length - 1; i += 1) {
      if (current[paths[i]] === undefined) {
        return undefined;
      }
      current = current[paths[i]];
    }
    return current;
  },
  getHtmlNameFromSchemaName: ({ jsonSchemaName, itemsArrayIndex = null }) => {
    if (itemsArrayIndex !== null && jsonSchemaName) {
      const simpleName = jsonSchemaName?.replace(/properties./g, '');
      const names = simpleName?.split('.items.');
      const name = `${names[0]}[${itemsArrayIndex}].${names[1]}`;
      return name;
    }
    return jsonSchemaName?.replace(/properties./g, '');
  },
  getAttributeFromSchema: (schema, attrPath) => {
    try {
      const keyNames = attrPath.split('.');
      let data = schema;

      for (let i = 0; i < keyNames.length; i += 1) {
        data = data[keyNames[i]];
      }
      return data;
    } catch (err) {
      // TODO: Finish and check it
      return schema;
    }
  },
  createNestedObjectFromDotNotation: propertyString => {
    const properties = propertyString.split('.');
    const result = {};

    properties.reduce((obj, prop, index) => {
      // eslint-disable-next-line no-param-reassign
      obj[prop] = index === properties.length - 1 ? null : {};
      return obj[prop];
    }, result);

    return result;
  },
};

export default FormJsonSchemaUtil;
