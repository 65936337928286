import { useIntl } from 'react-intl';
import { FormLabel as FormLabelMui } from '@mui/material';
import Required from '../Required';

function FormLabel({
  children = null,
  required,
  label = '',
  localeId = null,
  dataType,
  ...props
}) {
  const {
    formMode,
    type,
    masterData,
    hasConstantValue,
    formId,
    jsonSchema,
    ...labelProps
  } = props;
  const { formatMessage } = useIntl();

  return (
    <FormLabelMui {...labelProps}>
      {children ||
        formatMessage({
          id: localeId,
          defaultMessage: label,
        })}
      <Required show={required} />
    </FormLabelMui>
  );
}

export default FormLabel;
