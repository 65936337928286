import { useForm } from 'react-hook-form';
import { ajvResolver } from '@hookform/resolvers/ajv';
import { fullFormats } from 'ajv-formats/dist/formats';
import { nanoid } from 'nanoid';
import { observer } from 'mobx-react';
// eslint-disable-next-line camelcase
import { unstable_usePrompt } from 'react-router-dom';
import { useEffect, useImperativeHandle, useMemo } from 'react';
import { merge } from 'lodash';
import { useStore } from '../../MobxProvider';
// eslint-disable-next-line import/no-cycle
import renderComponents from './lib/renderComponents';
import getDefaultValues, { handleNullValue } from './lib/getDefaultValues';
import { DYNAMIC_FORM_MODE } from './lib/constants';

function DynamicForm({
  formMode = DYNAMIC_FORM_MODE.VIEW,
  uiSchema,
  jsonSchema,
  onSubmitSuccess,
  onSubmitError,
  reference,
  context,
  ajvOptions = null,
  additionalComponents = {},
  initialFormValues = {},
  masterData = [],
  formId,
  errors: initialErrors = null,
  loadingComponent = null,
  initialized = false,
  effectFunctions = null,
  unsavedChangeMessage = 'There are some unsaved changes in the form. Do you want to discard all the changes?',
  formatMessage = undefined,
  ignoreUnSaved = true,
  memoParams = [],
}) {
  const store = useStore();
  // const [formElements, setFormElements] = useState(null);
  let formElements = null;
  const defaultValues = handleNullValue(
    merge(getDefaultValues(jsonSchema), initialFormValues),
  );

  const {
    control,
    handleSubmit,
    getValues,
    reset,
    watch,
    setError,
    setValue,
    formState: { isDirty: dirty, isSubmitting, isSubmitted },
  } = useForm({
    context: context || nanoid(),
    defaultValues,
    resolver: ajvResolver(jsonSchema, {
      formats: fullFormats,
      allErrors: true,
      removeAdditional: true,
      ...ajvOptions,
    }),
  });

  const setErrors = (errorsArg = []) => {
    if (errorsArg && Array.isArray(errorsArg)) {
      errorsArg.every(errObj => {
        const field = errObj?.path?.replace('$.', '');
        if ((errObj?.field || errObj?.path) && errObj?.errorMessage) {
          setError(field.split('/').join('.').replace(/^\./, ''), {
            message: errObj.errorMessage,
          });
        }
        return true;
      });
    }
  };

  useEffect(() => {
    if (formMode === DYNAMIC_FORM_MODE.CREATE) {
      store.domain.formState.deleteState(formId);
    }
  }, [formMode]);

  useEffect(() => {
    store.domain.formState.setState(formId, defaultValues);
  }, []);

  useImperativeHandle(reference, () => ({
    submitForm(additonalData) {
      handleSubmit(
        data => onSubmitSuccess(data, additonalData),
        data => onSubmitError(data, additonalData),
      )();
    },
    getValues() {
      return getValues();
    },
    reset() {
      reset(initialFormValues);
    },
    update(formData) {
      reset(formData);
    },
    setValue(name, value) {
      setValue(name, value);
    },
    setErrors(errors) {
      setErrors(errors);
    },
  }));

  useEffect(() => {
    const subscription = watch(value => {
      store.domain.formState.setState(formId, value);
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  formElements = useMemo(
    () =>
      renderComponents({
        setValue,
        uiSchema,
        control,
        jsonSchema,
        additionalComponents,
        formMode,
        masterData,
        formId,
        effectFunctions,
        formatMessage,
        getValues,
      }),
    [...memoParams, masterData],
  );

  unstable_usePrompt({
    message: unsavedChangeMessage,
    when: !ignoreUnSaved && dirty && !isSubmitting && !isSubmitted,
  });

  useEffect(() => {
    setErrors(initialErrors);
  }, [initialErrors]);

  if (!formId) {
    throw new Error('Form id not provided');
  }

  if (!initialized) {
    return loadingComponent;
  }

  if (formId && formElements) {
    return (
      <form onSubmit={handleSubmit(onSubmitSuccess, onSubmitError)}>
        <div>{formElements}</div>
      </form>
    );
  }
  return <p>Error generating the form</p>;
}

export default observer(DynamicForm);
