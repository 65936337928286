/* eslint-disable prettier/prettier */
import { Fab, CircularProgress, Stack, Typography } from '@mui/material';
import { nanoid } from 'nanoid';
import useMediaQuery from '@mui/material/useMediaQuery';
// import isModule from 'is-module';

/*
 * position  = { left, top, bottom, right } all boolean OR null
 */

const style = buttonPosition =>
  buttonPosition
    ? {
      position: 'fixed',
      zIndex: 2,
      ...(buttonPosition.left && { left: '50px' }),
      ...(buttonPosition.top && { top: '50px' }),
      ...(buttonPosition.right && { right: '50px' }),
      ...(buttonPosition.bottom && { bottom: '50px' }),
    }
    : {};

function FloatingButton({
  async = false,
  onClick = () => undefined,
  inProcess = false,
  Icon = null,
  processingLabel = 'processing...',
  position = null,
  // display = 'flex',
  children,
  addMarginBottomToBody = true,
  zIndex,
  fontSize,
  Mobile,
  ...rest
}) {
  const onButtonClick = e => {
    onClick(e);
  };

  const isMobile = useMediaQuery('max-width:500px');
  const IconNode =
    // eslint-disable-next-line no-nested-ternary
    inProcess && async ? (
      <CircularProgress
        sx={{ width: '20px !important', height: '20px !important', mr: 1 }}
        color="primary"
      />
    ) : Icon ? (
      <Icon sx={{ mr: 0.6, fontSize }} />
    ) : null;

  const label = inProcess && async ? processingLabel : children;

  if (addMarginBottomToBody) {
    // This is needed so that when you scroll to page bottom, the floating button appear as if they are the last elements on page
    document.body.style['margin-bottom'] = '120px';
  }
  return (
    <Fab
      style={(style(position), { marginBottom: '0.5rem', zIndex, fontSize, flexDirection: Mobile ? 'column' : 'row', marginLeft: Mobile ? '6px' : '', alignItems: 'center' })}
      variant="extended"
      color={inProcess ? 'secondary' : 'primary'}
      size={isMobile ? 'small' : 'medium'}
      onClick={onButtonClick}
      disabled={inProcess}
      {...rest}>
      {IconNode}
      <Typography sx={{ fontSize, fontWeight: Mobile ? 600 : '' }}>{label}</Typography>

    </Fab>
  );
}

export function StackedFloatingButtons({
  buttons,
  position = null,
  display = 'flex',
  zIndex = 100,
  Mobile,
  fontSize
}) {
  // const isMobile = useMediaQuery('max-width:500px');

  return (
    <Stack
      sx={{ display, flexWrap: 'wrap', marginTop: '5rem', zIndex }}
      style={{ ...style(position), right: Mobile ? '4px' : '50px' }}
      direction="row"
      spacing={2}>
      {buttons.map(buttonProp => (
        <FloatingButton key={nanoid()} {...buttonProp} zIndex={zIndex} fontSize={fontSize} Mobile={Mobile} />
      ))}
    </Stack>
  );
}

export default FloatingButton;
