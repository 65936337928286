import { styled } from '@mui/material';

const Line = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.secondary.light,
  height: '1px',
  display: 'block',
  margin: `-5px ${theme.spacing(2)} auto`,
}));

function Seperator() {
  return <Line />;
}

export default Seperator;
