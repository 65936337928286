import { useTheme } from '@mui/material';

function Required({ show = false }) {
  const theme = useTheme();
  return (
    <span
      style={{
        display: show ? 'inline-flex' : 'none',
        color: theme.palette.error.main,
        marginLeft: '2px',
        fontSize: '16px',
      }}>
      *
    </span>
  );
}

export default Required;
